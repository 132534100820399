import { useCallback, useEffect } from 'react'
import { CustomerCreate, FormFieldType } from '@open-tender/types'
import { useProfileForm } from '@open-tender/utils'
import {
  fetchCustomer,
  resetLoginError,
  selectContent,
  selectCustomer,
  updateCustomer,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonSubmit, FormError, FormInputs, FormSubmit } from 'components'

const ProfileForm = ({
  id = 'account-form',
  buttonText = 'Update Account',
  windowRef,
  callback,
}: {
  id?: string
  buttonText?: string
  windowRef?: React.RefObject<HTMLDivElement>
  callback?: (data: CustomerCreate) => void
}) => {
  const dispatch = useAppDispatch()
  const { profile: config } = useAppSelector(selectContent) || {}
  const optionalFields = config?.displayed || []
  const { profile, loading, error } = useAppSelector(selectCustomer)
  const update = useCallback(
    (data: CustomerCreate) =>
      dispatch(updateCustomer({ data })).then((result) => {
        if (result.meta.requestStatus === 'fulfilled') {
          callback?.(data)
        }
      }),
    [callback, dispatch]
  )
  const {
    submitRef,
    data,
    errors,
    submitting,
    fields,
    handleChange,
    handleSubmit,
  } = useProfileForm(profile, loading, error, update, undefined, optionalFields)

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  useEffect(() => {
    if (!error) return

    if (windowRef?.current) {
      windowRef.current.scrollTop = 0
    } else {
      window.scrollTo(0, 0)
    }

    const timeout = setTimeout(() => {
      const firstErrorElement =
        document.querySelector<HTMLInputElement>('.error')
      if (firstErrorElement) setTimeout(() => firstErrorElement.focus())
    })

    return () => clearTimeout(timeout)
  }, [error, windowRef])

  useEffect(() => {
    if (!submitting && !windowRef) {
      window.scrollTo(0, 0)
    }
  }, [submitting, windowRef])

  useEffect(() => {
    dispatch(fetchCustomer())
    return () => {
      dispatch(resetLoginError())
    }
  }, [dispatch])

  return (
    <form id={id} onSubmit={handleSubmit} noValidate>
      <FormError errMsg={errors.form} style={{ margin: '0 0 2rem' }} />
      <FormInputs
        fields={fields}
        data={data || {}}
        onChange={onChange}
        errors={errors}
      />
      <FormSubmit>
        <ButtonSubmit submitRef={submitRef} submitting={submitting}>
          {submitting ? 'Submitting...' : buttonText}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default ProfileForm
