import {
  CartItem,
  CartItemGroups,
  CartLevels,
  MenuCategory,
} from '@open-tender/types'
import {
  makeSuspendUnitlMsg,
  slugify,
  useMenuItemsFilter,
} from '@open-tender/utils'
import { sendMenuView } from 'app/analytics'
import { MenuSection } from 'components/pages/Menu/MenuTop'
import sortBy from 'lodash/sortBy'
// @ts-ignore
import kountSDK from '@kount/kount-web-client-sdk'
import {
  selectBrand,
  selectContentSection,
  selectDisplaySettings,
  selectOrder,
  selectSelectedAllergenNames,
  selectSelectedTagNames,
  selectTimezone,
  useAppSelector,
} from '@open-tender/cloud'

export const getActiveElement = (elements: HTMLElement[], offset = 0) => {
  return elements
    .filter((i) => i.getBoundingClientRect().top <= offset)
    .reduce((max: HTMLElement | null, i: HTMLElement) => {
      return max &&
        max.getBoundingClientRect().top > i.getBoundingClientRect().top
        ? max
        : i
    }, null)
}

// https://stackoverflow.com/questions/51229742/javascript-window-scroll-behavior-smooth-not-working-in-safari
export const smoothHorizontalScrolling = (
  container: HTMLElement,
  time: number,
  amount: number,
  start: number
) => {
  let eAmt = amount / 100
  let curTime = 0
  let scrollCounter = 0
  while (curTime <= time) {
    window.setTimeout(shs, curTime, container, scrollCounter, eAmt, start)
    curTime += time / 100
    scrollCounter++
  }
}

const shs = (
  elem: HTMLElement,
  scrollCounter: number,
  eAmt: number,
  start: number
) => {
  const scrolledAmount = eAmt * scrollCounter + start
  elem.scrollLeft = scrolledAmount
}

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const generateRandomSessionID = () => {
  return Math.floor(Math.random() * 9000000000) + 1000000000
}

export const sha256 = async (value: string) => {
  const utf8 = new TextEncoder().encode(value)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', utf8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('')
  return hashHex
}
export const isPennStationBrand = window.location.href.includes('penn-station')
export const isFarmerBoysBrand = window.location.href.includes('farmerboys')
export const isSandbox =
  window.location.href.includes('sandbox') ||
  window.location.href.includes('localhost')

export const sendCategoryEvent = (category: MenuCategory) => {
  const combinedItems = category.children.reduce((items, child) => {
    return [...items, ...child.items]
  }, category.items)
  const items = combinedItems
    ? sortBy(combinedItems, 'menu_position').map((i) => ({
        ...i,
        list_id: category.id.toString(),
        list_name: category.name,
      }))
    : []
  sendMenuView({ category, items })
}

export const sendCategoryEventInScrollableMenu = (
  categories: MenuCategory[],
  menuSections: MenuSection[],
  activeSection: string
) => {
  const category = categories.find((c) => slugify(c.name) === activeSection)
  if (category) {
    sendMenuView({
      category,
      items: category.items.map((i) => ({
        ...i,
        list_id: category.id.toString(),
        list_name: category.name,
      })),
    })
  } else {
    const section = menuSections.find(
      (s) => s.name && slugify(s.name) === activeSection
    )
    if (section) {
      sendMenuView({
        category: { name: section.name },
        items:
          section.items?.map((i) => ({
            ...i,
            list_id: section.name.toLowerCase(),
            list_name: section.name,
          })) ?? [],
      })
    }
  }
}

export type UpsellMenuPosition = 'MENU_ITEM' | 'CART' | 'CHECKOUT'

export const makeUpsellsAnalyticsItems = (
  cart: CartItem[],
  upsellItems: CartItem[],
  upsells_position: UpsellMenuPosition
) => {
  return upsellItems.map((item, index) => {
    const parentItems =
      cart?.filter((cartItem) => cartItem.upsellItems.includes(item.id)) || []
    const parent_item_name = parentItems.map((i) => i.name).join(',')
    const parent_item_id = parentItems.map((i) => i.id.toString()).join(',')
    return {
      ...item,
      list_id: 'upsells',
      list_name: 'Upsells',
      parent_item_name,
      parent_item_id,
      upsells_position,
      menu_position: index + 1,
    }
  })
}

export const getOptionDetailsFromLevels = (
  groups: CartItemGroups,
  levels: CartLevels,
  optionLevel: number = 1
): Record<string, string | number> => {
  const [[groupId, optionId], ...rest] = levels
  const group = groups.find((g) => g.id === groupId)
  const option = group?.options.find((o) => o.id === optionId)
  const quantity = group?.quantity || 0

  if (!group || !option) return {}
  const parentOption = {
    ...(optionLevel === 1 && { group: group.name }),
    'group-quantity': quantity,
    [`option${optionLevel}`]: option.name,
    [`option${optionLevel}-quantity`]: option.quantity,
  }
  if (!rest.length) return parentOption
  return {
    ...parentOption,
    ...getOptionDetailsFromLevels(option?.groups, rest, optionLevel + 1),
  }
}

export interface KountParams {
  SessionID: string
  KountClientID: string
}

export interface KountConfig {
  clientID: string
  environment: 'TEST' | 'PROD'
  isSinglePageApp: boolean
}

export const initKount = (
  clientID: string,
  environment: KountConfig['environment'],
  callback: (params: KountParams) => void
) => {
  const sessionId = generateUUID()
  const kountConfig: KountConfig = {
    clientID,
    environment,
    isSinglePageApp: true,
  }
  // @ts-ignore
  kountConfig.callbacks = { 'collect-begin': callback }
  kountSDK(kountConfig, sessionId)
}

export const useMenuFilters = () => {
  const { tagsFilter, allergensFilter } =
    useAppSelector(selectDisplaySettings) || {}
  const { view_suspended_item } = useAppSelector(selectBrand) || {}
  const { requestedAt } = useAppSelector(selectOrder)
  const selectedTagsNames = useAppSelector(selectSelectedTagNames)
  const selectedAllergens = useAppSelector(selectSelectedAllergenNames)
  const { filterItems } = useMenuItemsFilter(
    requestedAt,
    selectedTagsNames,
    selectedAllergens,
    view_suspended_item ?? 'HIDE',
    tagsFilter,
    allergensFilter
  )
  return { filterItems }
}

export const getRecaptchaKey = (useEnterprise?: boolean) => {
  const key = useEnterprise
    ? process.env.REACT_APP_ENTERPRISE_RECAPTCHA_KEY
    : process.env.REACT_APP_RECAPTCHA_KEY
  return key || null
}
export const useModifiersFilters = () => {
  const { view_suspended_item } = useAppSelector(selectBrand) || {}

  const filterModifiers = (groups: CartItemGroups): CartItemGroups => {
    if (view_suspended_item !== 'HIDE') return groups
    return groups.map((g) => {
      return {
        ...g,
        options: g.options
          .filter((o) => !o.isSoldOut)
          .map((o) => {
            return {
              ...o,
              groups: filterModifiers(o.groups),
            }
          }),
      }
    })
  }

  return filterModifiers
}

export const useSuspendUntil = (suspendedUntil: number | null | undefined) => {
  const tz = useAppSelector(selectTimezone)
  const { soldOutMessage } = useAppSelector(selectContentSection('menu')) || {}
  const { view_suspended_item } = useAppSelector(selectBrand) || {}

  const soldOutMsg = suspendedUntil
    ? makeSuspendUnitlMsg(
        suspendedUntil,
        tz,
        soldOutMessage,
        view_suspended_item === 'SHOW_DATETIME'
      )
    : null

  return soldOutMsg
}
