import styled from '@emotion/styled'
import {
  LoyaltyStatus,
  LoyaltyTiers as LoyaltyTiersType,
  LoyaltyType,
} from '@open-tender/types'
import { formatDollars, roundPoints } from '@open-tender/utils'
import { Body, Heading, ProgressBar } from 'components'

const LoyaltyTiersView = styled.div`
  margin: 4rem 0 0;
  text-align: left;
  overflow: hidden;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 4rem 0 0;
  }
`

const LoyaltyTiersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 3rem;
`

const LoyaltyTiersTitle = styled(Heading)`
  font-size: ${(props) => props.theme.fonts.sizes.big};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizesMobile.big};
  }
`

const LoyaltyTiersSubtitle = styled(Body)`
  margin: 0.5rem 0 0;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizesMobile.small};
  }
`

const LoyaltyTiers = ({
  loyaltyType,
  status,
  tiers,
  spendName,
}: {
  loyaltyType: LoyaltyType
  status: LoyaltyStatus
  tiers: LoyaltyTiersType
  spendName: string
}) => {
  const progress = parseFloat(status.progress)
  // const currentTier = tiers.find((i) => i.is_current_tier)
  const higherTiers = tiers.filter((i) => parseFloat(i.threshold) > progress)
  const nextTier = progress && higherTiers.length ? higherTiers[0] : null
  const remaining = nextTier ? parseFloat(nextTier.threshold) - progress : null
  const remainingStr = remaining
    ? loyaltyType === 'DOLLARS'
      ? formatDollars(remaining?.toString(), undefined, 0)
      : roundPoints(remaining)
    : null
  const progressStr =
    loyaltyType === 'DOLLARS'
      ? formatDollars(status.progress, undefined, 0)
      : roundPoints(status.progress)

  return (
    <LoyaltyTiersView>
      <LoyaltyTiersHeader>
        <div>
          <LoyaltyTiersTitle as="p">Current Loyalty Status</LoyaltyTiersTitle>
          {remainingStr && nextTier ? (
            <LoyaltyTiersSubtitle as="p">
              You're at {progressStr} {spendName}, which is {remainingStr} from
              achieving the next tier!
            </LoyaltyTiersSubtitle>
          ) : progress ? (
            <LoyaltyTiersSubtitle as="p">
              You're at {progressStr} {spendName} and off the charts!
            </LoyaltyTiersSubtitle>
          ) : (
            <LoyaltyTiersSubtitle as="p">
              Place your first order to start making progress.
            </LoyaltyTiersSubtitle>
          )}
        </div>
        {/* {currentTier && <LoyaltyTier tier={currentTier} />} */}
      </LoyaltyTiersHeader>
      <ProgressBar
        loyaltyType={loyaltyType}
        status={status}
        tiers={tiers}
        spendName={spendName}
      />
    </LoyaltyTiersView>
  )
}

export default LoyaltyTiers
