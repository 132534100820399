import { Children } from 'react'
import styled from '@emotion/styled'

const MenuItemsView = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.menuItems.container.maxWidth};
`

const MenuItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: -${(props) => props.theme.menuItems.container.gapHalf};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: -${(props) => props.theme.menuItems.container.gapHalfMobile};
  }
`

const MenuItemsChild = styled.div`
  label: MenuItemsChild;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: ${(props) => props.theme.menuItems.box.width};
  padding: ${(props) => props.theme.menuItems.container.gapHalf};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-basis: ${(props) => props.theme.menuItems.box.widthMobile};
    padding: ${(props) => props.theme.menuItems.container.gapHalfMobile};
  }
`

const makeNumbers = (n: number) => {
  let numbers: number[] = []
  for (let i = 2; i <= n; i++) {
    numbers.push(i)
  }
  return numbers
}

const makeEmpty = (perRow: number, count: number) => {
  const numbers = makeNumbers(perRow)
  return numbers.reduce((arr: number[], i, index) => {
    const leftOver = count % i
    return leftOver ? [...arr, index] : arr
  }, [])
}

const MenuItems = ({
  children,
}: {
  children?: JSX.Element | JSX.Element[]
}) => {
  const perRow = 5
  const arrayChildren = Children.toArray(children)
  const count = arrayChildren.length
  const empty = makeEmpty(perRow, count)
  return (
    <MenuItemsView>
      <MenuItemsContainer as="ul">
        {Children.map(arrayChildren, (child, index) => (
          <MenuItemsChild as="li" key={index}>
            {child}
          </MenuItemsChild>
        ))}
        {empty.map((index) => (
          <MenuItemsChild key={index} />
        ))}
      </MenuItemsContainer>
    </MenuItemsView>
  )
}

export default MenuItems
