import { useEffect } from 'react'
import { AnnouncementPageType } from '@open-tender/types'
import {
  fetchAnnouncementPage,
  resetAnnouncementsError,
  useAppDispatch,
} from '@open-tender/cloud'

export const fetchedAnnouncementPages: Record<AnnouncementPageType, boolean> = {
  ABOUT: false,
  ACCOUNT: false,
  CATERING: false,
  CHECKOUT: false,
  GUEST: false,
  HOME: false,
  MENU: false,
  ORDER_TYPE: false,
}

/**
 * Custom hook to fetch announcement page data only once per session
 * @param page - The announcement page type to fetch
 */
export const useAnnouncementPage = (page: AnnouncementPageType) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!fetchedAnnouncementPages[page]) {
      dispatch(fetchAnnouncementPage(page))
      fetchedAnnouncementPages[page] = true
    } else {
      dispatch(resetAnnouncementsError())
    }
  }, [dispatch, page])
}

export default useAnnouncementPage
