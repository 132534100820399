import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import {
  fetchAnnouncementPage,
  selectAnnouncementsPage,
  setOrderServiceType,
  selectBrand,
  selectPosts,
  fetchPosts,
  closeModal,
  useAppSelector,
  useAppDispatch,
  selectContentSection,
  selectCustomer,
} from '@open-tender/cloud'

import {
  Container,
  Content,
  HeaderGuest,
  Main,
  PageHero,
  ScreenreaderTitle,
} from '../..'
import { LandingOrderTypePost } from './LandingOrderTypePost'

const LandingContent = styled('div')`
  opacity: 0;
  animation: slide-up 0.25s ease-in-out 0.25s forwards;
  width: 100%;
  margin: 5rem auto;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 3rem auto;
  }

  p {
    line-height: ${(props) => props.theme.lineHeight};
  }
`

const LandingTitle = styled('div')`
  width: 100%;
  text-align: center;
  margin: 0 0 3rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0 0 1.5rem;
  }

  h2 {
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fonts.sizes.h3};
    }
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.fonts.sizes.h4};
    }
  }
`

const LandingOrderTypes = styled('div')`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -1.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0;
  }
`

const LandingLinks = styled('div')`
  width: 100%;
  text-align: center;
  margin: 3rem 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 1.5rem 0;
  }

  h3 {
    margin: 0 0 1rem;
  }
`

const LandingFooter = styled('div')`
  width: 100%;
  text-align: center;
  margin: 3rem 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 1.5rem 0;
  }
`

const Landing = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const announcements = useAppSelector(selectAnnouncementsPage('HOME'))
  const { posts } = useAppSelector(selectPosts)
  const { auth } = useAppSelector(selectCustomer)
  const brand = useAppSelector(selectBrand)
  const {
    title,
    subtitle,
    background,
    mobile,
    videoUrlMobile,
    showGuest,
    displayed: displayedDesktop,
    displayedMobile,
  } = useAppSelector(selectContentSection('guest')) || {}

  const displayed = isMobile ? displayedMobile : displayedDesktop
  const hasHero = isMobile ? mobile || videoUrlMobile : background
  const heroHeight = isMobile ? '24rem' : '44rem'
  const displayHero = displayed?.includes('HERO') && hasHero

  useEffect(() => {
    if (auth) {
      navigate('/account')
    } else if (!showGuest) {
      navigate('/order-type')
    } else if (brand?.pilot_slug) {
      navigate(`/locations/${brand?.pilot_slug}`)
    }
  }, [auth, showGuest, brand?.pilot_slug, navigate])

  useEffect(() => {
    dispatch(closeModal())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchAnnouncementPage('HOME'))
    dispatch(fetchPosts('order-types'))
  }, [dispatch])

  const handleOutpost = () => {
    dispatch(
      setOrderServiceType({
        orderType: 'OLO',
        serviceType: 'PICKUP',
        isOutpost: true,
      })
    )
    navigate('/locations')
  }

  const handlePickup = () => {
    dispatch(setOrderServiceType({ orderType: 'OLO', serviceType: 'PICKUP' }))
    navigate('/locations')
  }

  const handleDelivery = () => {
    dispatch(setOrderServiceType({ orderType: 'OLO', serviceType: 'DELIVERY' }))
    navigate('/locations')
  }

  const handleCatering = () => {
    dispatch(
      setOrderServiceType({ orderType: 'CATERING', serviceType: 'DELIVERY' })
    )
    navigate('/catering-address')
  }

  const handlers = {
    'order-types/pickup': handlePickup,
    'order-types/delivery': handleDelivery,
    'order-types/catering': handleCatering,
    'order-types/outpost': handleOutpost,
  }

  if (auth || !showGuest) return null

  return (
    <>
      <Helmet>
        <title>{brand?.title}</title>
      </Helmet>
      <Content>
        <HeaderGuest maxWidth="100%" />
        <Main>
          {displayHero && (
            <PageHero
              announcements={announcements}
              imageUrl={displayHero}
              height={heroHeight}
            />
          )}
          <ScreenreaderTitle>Home</ScreenreaderTitle>
          <LandingContent>
            <Container style={{ maxWidth: '100%' }}>
              <LandingTitle>
                <h2>{title}</h2>
              </LandingTitle>
              <LandingOrderTypes>
                {posts.map((post) => (
                  <LandingOrderTypePost
                    key={post.slug}
                    {...post}
                    onClick={handlers[post.slug as keyof typeof handlers]}
                  />
                ))}
              </LandingOrderTypes>
              <LandingLinks>
                <h3>Other Stuff...</h3>
                <p>
                  <a
                    href="https://properfood.digitalgiftcardmanager.com/landingpage"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Purchase Gift Cards
                  </a>{' '}
                  <span>or</span> <Link to="/donations">Make A Donation</Link>
                </p>
              </LandingLinks>
              <LandingFooter>
                <p>{subtitle}</p>
              </LandingFooter>
            </Container>
          </LandingContent>
        </Main>
      </Content>
    </>
  )
}

Landing.displayName = 'Landing'
export default Landing
