import styled from '@emotion/styled'
import { selectBrand, useAppSelector } from '@open-tender/cloud'
import { roundPoints } from '@open-tender/utils'
import { Body, Box, Button, Heading, Loading } from 'components'
import { usePointsShop, useTplsPointsShop } from 'hooks'
import { CheckCircleSolid } from 'components/icons'
import { useNavigate } from 'react-router-dom'

const ClaimedContainer = styled(Box)`
  width: 100%;
  padding: ${(props) =>
    props.theme.cards.default.bgColor === 'transparent'
      ? '0'
      : props.theme.layout.paddingMobile};
  margin: 20px 0 30px;
  background-color: white;
  border-radius: 8px;
  align-items: center;
`

const ClaimedTitleView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 20px 0;
`

const ClaimedBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;
  gap: 10px;
`
const LoadingContainer = styled.div`
  height: 16px;
`
const ClaimedPointsCard = ({
  onClose,
  claimedPoints,
}: {
  onClose: () => void
  claimedPoints: number
}) => {
  const navigate = useNavigate()
  const { tpls } = useAppSelector(selectBrand) || {}
  const hasTplPointsShop =
    tpls === 'COMO' || tpls === 'PUNCHH' || tpls === 'SPARKFLY'
  const usePointsShopHook = hasTplPointsShop ? useTplsPointsShop : usePointsShop
  const { pointsBalance, isLoading } = usePointsShopHook()

  const handleClose = () => {
    onClose()
  }

  const handleVisitPointsShop = () => {
    navigate('/points-shop')
  }
  return (
    <ClaimedContainer>
      <ClaimedTitleView>
        <CheckCircleSolid color="#4A801F" size={24} />
        <Heading>Points Claimed!</Heading>
      </ClaimedTitleView>
      <ClaimedBody>
        {claimedPoints ? (
          <Body>
            You've successfully claimed{' '}
            <Heading size="main">{roundPoints(claimedPoints)} points.</Heading>
          </Body>
        ) : null}
        {isLoading ? (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        ) : (
          <Body>
            Your current balance is{' '}
            <Heading size="main">{roundPoints(pointsBalance)} points.</Heading>
          </Body>
        )}
      </ClaimedBody>
      <ButtonContainer>
        <Button onClick={handleVisitPointsShop}>Visit Points Shop</Button>
        <Button color="secondary" onClick={handleClose}>
          Close
        </Button>
      </ButtonContainer>
    </ClaimedContainer>
  )
}

export default ClaimedPointsCard
