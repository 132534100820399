import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { makeMenuItemLookup, makeFavorites } from '@open-tender/utils'
import {
  fetchCustomerFavorites,
  selectBrand,
  selectContentSection,
  selectCustomer,
  selectCustomerFavorites,
  selectMenu,
  selectMenuSlug,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Content, Loading, Main, ScreenreaderTitle } from 'components'
import {
  MenuCategoryHeader,
  MenuHeader,
  MenuItems,
  MenuItem,
  MenuCategoryView,
  MenuCategoryWithHeaderView,
} from '../Menu'
import CategorySwitch from '../Category/CategorySwitch'
import { sendMenuView } from 'app/analytics'
import { useMenuFilters } from 'utils/helpers'

const MenuFavorites = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [count, setCount] = useState(0)
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const { categories, soldOut } = useAppSelector(selectMenu) || {}
  const { title, subtitle } =
    useAppSelector(selectContentSection('favorites')) || {}
  const menuSlug = useAppSelector(selectMenuSlug)
  const { auth } = useAppSelector(selectCustomer)
  const hasCustomer = auth ? true : false

  const itemLookup = useMemo(() => makeMenuItemLookup(categories), [categories])
  const favs = useAppSelector(selectCustomerFavorites)
  const { filterItems } = useMenuFilters()
  const favorites = useMemo(
    () =>
      favs.entities
        ? filterItems(makeFavorites(favs.entities, itemLookup, soldOut)).map(
            (i, index) => ({
              ...i,
              list_id: 'favorites',
              list_name: 'Favorites',
              menu_position: index + 1,
            })
          )
        : [],
    [favs.entities, filterItems, itemLookup, soldOut]
  )
  const hasFavorites = favorites && favorites.length > 0
  const favCount = hasFavorites ? favorites.length : 0
  const updating = favCount !== count
  const showLoading = favs.loading === 'pending' && !hasFavorites ? true : false
  const shouldRedirect =
    !hasCustomer || (!hasFavorites && favs.loading !== 'pending')

  useEffect(() => {
    if (hasCustomer) {
      dispatch(fetchCustomerFavorites())
    }
  }, [dispatch, hasCustomer])

  useEffect(() => {
    setCount(favCount)
  }, [favCount])

  useEffect(() => {
    if (shouldRedirect) navigate(menuSlug)
  }, [navigate, shouldRedirect, menuSlug])

  useEffect(() => {
    sendMenuView({
      category: { name: 'Favorites' },
      items: favorites,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (showLoading || updating) return null

  return (
    <>
      <Helmet>
        <title>Favorites | {siteTitle}</title>
      </Helmet>
      <Content scrollTop={false}>
        <MenuHeader />
        <Main>
          <ScreenreaderTitle>{title}</ScreenreaderTitle>
          <CategorySwitch />
          <MenuCategoryWithHeaderView>
            <MenuCategoryView>
              <MenuCategoryHeader title={title} subtitle={subtitle} />
              {showLoading || updating ? (
                <Loading />
              ) : (
                <MenuItems>
                  {favorites.map((item, index) => (
                    <MenuItem
                      key={`${item.id}-${index}`}
                      item={item}
                      favorite={item.favorite}
                    />
                  ))}
                </MenuItems>
              )}
            </MenuCategoryView>
          </MenuCategoryWithHeaderView>
        </Main>
      </Content>
    </>
  )
}

export default MenuFavorites
