import {
  openModal,
  selectOrder,
  useAppDispatch,
  useAppSelector,
  validateOrder,
} from '@open-tender/cloud'
import {} from '@open-tender/cloud'
import { cardIconsMap } from 'components/cardIcons'
import CheckoutButton from './CheckoutButton/CheckoutButton'

interface CheckoutCreditCardAddProps {
  isPaid?: boolean
}

const CheckoutCreditCardAdd = ({ isPaid }: CheckoutCreditCardAddProps) => {
  const dispatch = useAppDispatch()
  const { revenueCenter } = useAppSelector(selectOrder)
  const { revenue_center_id = null } = revenueCenter || {}

  const onClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    const args = {
      callback: () => dispatch(validateOrder()),
      revenue_center_id,
    }
    dispatch(openModal({ type: 'creditCard', args }))
  }

  return (
    <CheckoutButton
      icon={cardIconsMap['OTHER']}
      title="Add new credit card to profile"
      onPress={onClick}
      isApplied={false}
      disabled={isPaid}
      applyText="Add"
    />
  )
}

export default CheckoutCreditCardAdd
