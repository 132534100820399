import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { isMobile } from 'react-device-detect'
import { OrderCreatePoints } from '@open-tender/types'
import { roundPoints, useCartPoints } from '@open-tender/utils'
import {
  resetTip,
  selectBrand,
  selectCheckout,
  selectOrder,
  selectSidebar,
  toggleSidebar,
  updateForm,
  useAppDispatch,
  useAppSelector,
  validateOrder,
} from '@open-tender/cloud'
import { ButtonLink, CartSummary, Heading, Text } from 'components'
import { CheckSummary, Loading } from '../..'

const CheckoutCartView = styled.div`
  padding: 0 0 ${(props) => props.theme.layout.navHeight};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 2rem 0 0;
  }
`

interface CheckoutCartHeaderProps {
  hasPoints?: boolean
}

const CheckoutCartHeader = styled.div<CheckoutCartHeaderProps>`
  height: ${(props) => props.theme.layout.navHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    height: ${(props) =>
      props.hasPoints ? props.theme.layout.navHeight : '3rem'};
  }

  p:first-of-type {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }

  p:last-of-type {
    margin: 0.5rem 0 0;
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`

const CheckoutCartEdit = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.layout.padding} 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }

  span {
    display: block;
    padding: 0 1rem;
    font-size: ${(props) => props.theme.fonts.sizes.small};
    color: ${(props) => props.theme.colors.tertiary};
  }

  button {
    display: block;
    text-decoration: none;
    font-size: ${(props) => props.theme.fonts.sizes.small};
    color: ${(props) => props.theme.links.dark.color};
    padding: 0 0 0.2rem;
    border-bottom: 0.1rem solid ${(props) => props.theme.colors.primary};

    &:hover,
    &:active {
      color: ${(props) => props.theme.links.dark.hover};
      border-color: ${(props) => props.theme.links.dark.hover};
    }
  }
`

const CheckoutCart = () => {
  const dispatch = useAppDispatch()
  const [hasOpened, setHasOpened] = useState(false)
  const { tpls } = useAppSelector(selectBrand) || {}
  const { cart } = useAppSelector(selectOrder)
  const { isOpen } = useAppSelector(selectSidebar)
  const { check, form, loading, submitting } = useAppSelector(selectCheckout)
  const updatePoints = (points: OrderCreatePoints) =>
    dispatch(updateForm({ points }))
  const {
    cartDiff,
    cartWithPoints,
    points,
    pointsBalance,
    pointsRemaining,
    pointsApplied,
    pointsName,
    pointsObj,
    setCartLength,
  } = useCartPoints(cart, check, form, updatePoints)
  const pointsItems = cartWithPoints
    .map((i) => i.points?.per || 0)
    .filter((i) => i > 0)
  const pointsMin = pointsItems.length ? Math.min(...pointsItems) : null
  const pointsRedeemable =
    pointsBalance && pointsMin !== null ? pointsMin < pointsBalance : false

  const editCart = () => {
    dispatch(toggleSidebar())
  }

  useEffect(() => {
    if (!isOpen) {
      if (hasOpened) {
        dispatch(resetTip())
        dispatch(validateOrder())
      }
    } else {
      setHasOpened(true)
    }
  }, [dispatch, isOpen, hasOpened])

  useEffect(() => {
    dispatch(validateOrder())
  }, [dispatch, pointsApplied])

  useEffect(() => {
    if (cartDiff < 0) {
      setCartLength(cart?.length || 0)
      dispatch(updateForm({ points: [] }))
      dispatch(resetTip())
      dispatch(validateOrder())
    }
  }, [dispatch, cartDiff, cart?.length, setCartLength])

  return (
    <CheckoutCartView>
      <CheckoutCartHeader hasPoints={points ? true : false}>
        {points ? (
          <div>
            <Heading as="p">
              You've got {roundPoints(points.balance)} {pointsName || 'points'}!
            </Heading>
            {tpls ? (
              <Text as="p">
                Check for eligible discounts in the rewards section.
              </Text>
            ) : pointsRemaining < pointsBalance ? (
              <Text as="p" color="alert">
                {roundPoints(pointsApplied)} {pointsName} applied.{' '}
                {roundPoints(pointsRemaining)} remaining. See discount below.
              </Text>
            ) : pointsRedeemable ? (
              <p>Click the buttons below to apply.</p>
            ) : null}
          </div>
        ) : null}
      </CheckoutCartHeader>
      <CartSummary cart={cartWithPoints} pointsObj={pointsObj} />
      {check && (
        <CheckSummary
          check={check}
          tenders={form.tenders}
          updating={loading === 'pending' && !submitting}
          loader={<Loading />}
          showTenders={!isMobile}
        />
      )}
      <CheckoutCartEdit>
        <ButtonLink onClick={editCart}>Edit Cart</ButtonLink>
      </CheckoutCartEdit>
    </CheckoutCartView>
  )
}

export default CheckoutCart
