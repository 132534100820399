import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  PizzaBuilderBaseView,
  PizzaBuilderContent,
  // PizzaBuilderHeader,
  PizzaBuilderOptionsContent,
  PizzaBuilderOptionsTitle,
  PizzaBuilderOptionsView,
  PizzaBuilderPizza,
  PizzaBuilderView,
} from './PizzaBuilder.styled'
import { useBuilder } from '@open-tender/utils'
import { CartItemGroups, CartLevels } from '@open-tender/types'
import PizzaBuilderTopping from './PizzaBuilderTopping'
import PizzaBuilderOption from './PizzaBuilderOption'
import Body from 'components/Body'
import { GroupOption } from './types'

const pizza = require('../../assets/images/cheese-pizza.webp')
const recatanglePizza = require('../../assets/images/rectangle-pizza.webp')

const PizzaBuilder = ({
  builder,
  levels,
  modifierGroups,
}: {
  builder: ReturnType<typeof useBuilder>
  levels: CartLevels | undefined
  modifierGroups: CartItemGroups
}) => {
  const [pizzaSize, setPizzaSize] = useState<number>(900)
  const pizzaContainerRef = useRef<HTMLDivElement>(null)
  const isRectangular = builder.item.itemShape === 'RECTANGULAR'
  const handleOnResize = useCallback(() => {
    if (!pizzaContainerRef.current) return
    setPizzaSize(pizzaContainerRef.current.getBoundingClientRect().height)
  }, [])

  useEffect(() => {
    handleOnResize()
    window.addEventListener('resize', handleOnResize)
    return () => window.removeEventListener('resize', handleOnResize)
  }, [handleOnResize])

  const toppingsGroups = modifierGroups.filter(
    (group) =>
      group.isPizza &&
      !group.isSize &&
      !!group.options.find((option) => option.quantity > 0) &&
      !group.excludeFromBuilder
  )

  const options = useMemo<GroupOption[]>(
    () =>
      toppingsGroups.flatMap((group) =>
        group.options
          .filter((option) => !option.excludeFromBuilder && option.quantity > 0)
          .map((option) => ({
            group,
            option,
          }))
      ),
    [toppingsGroups]
  )

  return (
    <PizzaBuilderView>
      {/* <PizzaBuilderHeader /> */}
      <PizzaBuilderContent>
        <PizzaBuilderPizza>
          <PizzaBuilderBaseView
            ref={pizzaContainerRef}
            url={isRectangular ? recatanglePizza : pizza}
            isRectangular={isRectangular}
          >
            {options.map((option, index) => (
              <PizzaBuilderTopping
                pizzaSize={pizzaSize}
                isRectangular={isRectangular}
                index={index}
                option={option.option}
                key={option.option.id}
              />
            ))}
          </PizzaBuilderBaseView>
        </PizzaBuilderPizza>
        <PizzaBuilderOptionsView>
          <PizzaBuilderOptionsTitle size="big">
            Your toppings
          </PizzaBuilderOptionsTitle>
          {toppingsGroups.length > 0 ? (
            <PizzaBuilderOptionsContent>
              {options.map((option) => (
                <PizzaBuilderOption
                  group={option.group}
                  levels={levels}
                  builder={builder}
                  option={option.option}
                  key={option.option.id}
                />
              ))}
            </PizzaBuilderOptionsContent>
          ) : (
            <PizzaBuilderOptionsContent>
              <Body>No toppings selected currently.</Body>
            </PizzaBuilderOptionsContent>
          )}
        </PizzaBuilderOptionsView>
      </PizzaBuilderContent>
    </PizzaBuilderView>
  )
}

export default PizzaBuilder
