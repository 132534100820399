import { Header, HeaderLogo } from '.'
import { Back, Cart, NavMenu, OrderNow, ClaimRewardCodeButton } from './buttons'

const HeaderDefault = ({
  maxWidth = '100%',
  path,
  showOrderNow = true,
  showLogo = true,
  showClaimRewardCodeButton = false,
}: {
  maxWidth?: string
  path?: string
  showOrderNow?: boolean
  showLogo?: boolean
  showClaimRewardCodeButton?: boolean
}) => {
  return (
    <Header
      maxWidth={maxWidth}
      title={
        showLogo ? (
          <HeaderLogo />
        ) : (
          showClaimRewardCodeButton && <ClaimRewardCodeButton />
        )
      }
      left={<Back path={path} />}
      right={
        <>
          {showLogo && showClaimRewardCodeButton && <ClaimRewardCodeButton />}
          <Cart />
          {showOrderNow && <OrderNow />}
          <NavMenu />
        </>
      }
    />
  )
}

export default HeaderDefault
