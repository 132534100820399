import styled from '@emotion/styled'
import { Body, Heading } from 'components'

const MenuCategoryHeaderView = styled.div`
  margin: 0 auto 3rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0 auto 1.5rem;
  }
`

const MenuCategoryHeaderTitle = styled(Heading)<{ isChild?: boolean }>`
  margin: 0 0 0 -0.1rem;
  font-size: ${(props) =>
    props.theme.fonts.sizes[props.isChild ? 'xBig' : 'xxBig']};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) =>
      props.theme.fonts.sizes[props.isChild ? 'big' : 'xBig']};
  }
`

const MenuCategoryHeaderSubtitle = styled(Body)`
  margin: 1rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0.5rem 0 0;
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`

interface MenuCategoryHeaderProps {
  title?: string
  subtitle?: string | null
  isChild?: boolean
}

const MenuCategoryHeader = ({
  title,
  subtitle,
  isChild,
}: MenuCategoryHeaderProps) => {
  return (
    <MenuCategoryHeaderView>
      <MenuCategoryHeaderTitle as="h2" isChild={isChild}>
        {title}
      </MenuCategoryHeaderTitle>
      {subtitle && (
        <MenuCategoryHeaderSubtitle as="p">
          {subtitle}
        </MenuCategoryHeaderSubtitle>
      )}
    </MenuCategoryHeaderView>
  )
}

export default MenuCategoryHeader
