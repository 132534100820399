import { openModal } from '@open-tender/cloud'
import { Tag } from '../icons'
import { useAppDispatch } from '@open-tender/cloud'
import { isMobileOnly } from 'react-device-detect'
import Button from 'components/Button'

const Tags = ({ style }: { style?: React.CSSProperties }) => {
  const dispatch = useAppDispatch()

  return (
    <Button
      label="Menu Tags"
      style={style}
      icon={<Tag size={isMobileOnly ? 17 : 19} />}
      onClick={() => dispatch(openModal({ type: 'tags' }))}
      color="header"
      size="header"
    >
      {!isMobileOnly && 'Tags'}
    </Button>
  )
}

export default Tags
