import { useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { contains } from '@open-tender/utils'
import {
  selectCartQuantity,
  toggleSidebar,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ShoppingBag } from '../icons'
import {
  CartButtonButton,
  CartButtonContainer,
  CartButtonCount,
  CartButtonIcon,
  CartButtonView,
} from './CartButton.styled'
import ScreenreaderTitle from '../ScreenreaderTitle'

const CartButton = () => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const cartQuantity = useAppSelector(selectCartQuantity)
  const isItem = pathname.includes('/item/')
  const showCart = contains(pathname, ['menu']) && !isItem

  const toggle = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    dispatch(toggleSidebar())
  }

  return showCart ? (
    <CartButtonView role="region">
      <CartButtonContainer>
        {cartQuantity > 0 && (
          <CartButtonCount>
            <span>{cartQuantity}</span>
            <ScreenreaderTitle aria-live="polite">
              {cartQuantity} {cartQuantity === 1 ? 'item' : 'items'} currently
              in the cart
            </ScreenreaderTitle>
          </CartButtonCount>
        )}
        <CartButtonButton
          onClick={toggle}
          aria-label="Open cart to review order, press escape key to access at any time"
        >
          <CartButtonIcon>
            <ShoppingBag size={isMobile ? 28 : 32} />
          </CartButtonIcon>
        </CartButtonButton>
      </CartButtonContainer>
    </CartButtonView>
  ) : null
}

export default CartButton
