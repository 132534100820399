import styled from '@emotion/styled'
import Heading from '../Heading'

export const PizzaBuilderView = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props.theme.bgColors.tertiary};
  padding: ${(props) => props.theme.item.desktop.imagePaddingAll};
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const PizzaBuilderHeader = styled.div`
  width: 100%;
  flex: 0 0 8rem;
  background-image: linear-gradient(
      45deg,
      ${(props) => props.theme.colors.primary} 25%,
      transparent 25%,
      transparent 75%,
      ${(props) => props.theme.colors.primary} 75%,
      ${(props) => props.theme.colors.primary}
    ),
    linear-gradient(
      45deg,
      ${(props) => props.theme.colors.primary} 25%,
      transparent 25%,
      transparent 75%,
      ${(props) => props.theme.colors.primary} 75%,
      ${(props) => props.theme.colors.primary}
    );
  background-position: 0 0, 20px 20px;
  background-size: 40px 40px;
`

export const PizzaBuilderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  flex: 1 1 auto;
`

export const PizzaBuilderPizza = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: ${(props) => props.theme.layout.padding};
`

export const PizzaBuilderBaseView = styled.div<{
  url: string
  isRectangular?: boolean
}>`
  background-image: url('${(props) => props.url}');
  background-repeat: no-repeat;
  background-size: ${(props) => (props.isRectangular ? 'contain' : 'cover')};
  background-position: center;
  aspect-ratio: 1;
  max-width: 900px;
  max-height: 900px;
  position: relative;
  border-radius: ${(props) => (props.isRectangular ? '0' : '50%')};
  overflow: hidden;
  flex: 1 1 30rem;
`

export const PizzaBuilderOptionsView = styled.div`
  flex: 0 0 15rem;
  padding: ${(props) => props.theme.layout.padding};
  padding-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 0;
  }
`

export const PizzaBuilderOptionsContent = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: center;
`

export const PizzaBuilderOptionsTitle = styled(Heading)`
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  display: block;
  margin-bottom: 2rem;
`
