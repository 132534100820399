import {
  fetchCustomerTpls,
  selectBrand,
  selectContent,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import ScrollableItems from 'components/ScrollableItems'
import PointsShopReward from 'components/PointsShopReward'
import AccountSection from './AccountSection'
import { usePointsShop, useTplsPointsShop } from 'hooks'
import { useEffect } from 'react'

const AccountPointsShop = () => {
  const dispatch = useAppDispatch()

  const { pointsShop: config } = useAppSelector(selectContent) || {}
  const { tpls } = useAppSelector(selectBrand) || {}

  const hasTplPointsShop =
    tpls === 'COMO' || tpls === 'PUNCHH' || tpls === 'SPARKFLY'
  const usePointsShopHook = hasTplPointsShop ? useTplsPointsShop : usePointsShop
  const { rewards, isLoading, pointsBalance, error } = usePointsShopHook()

  useEffect(() => {
    dispatch(fetchCustomerTpls())
  }, [dispatch])

  if (error || !rewards.length) return null

  return (
    <AccountSection>
      <ScrollableItems
        title={config?.title}
        to={rewards.length > 2 ? '/points-shop' : undefined}
        // to="/points-shop"
        isLoading={isLoading}
        loadingText="Loading point shop..."
      >
        {rewards.map((reward) => (
          <PointsShopReward
            key={reward.id}
            reward={reward}
            pointsBalance={pointsBalance}
          />
        ))}
      </ScrollableItems>
    </AccountSection>
  )
}

export default AccountPointsShop
