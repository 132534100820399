import { openModal, useAppDispatch } from '@open-tender/cloud'
import {
  LoyaltyStatus,
  LoyaltyTier,
  LoyaltyTiers,
  LoyaltyType,
} from '@open-tender/types'
import { formatDollars, roundPoints } from '@open-tender/utils'
import Heading from '../Heading'
import Body from '../Body'
import {
  ProgressAmount,
  ProgressBarFill,
  ProgressPoint,
  ProgressPointButton,
  ProgressPointContainer,
  ProgressPointHover,
  ProgressView,
} from './ProgressBar.styled'

const ProgressBar = ({
  loyaltyType,
  status,
  tiers = [],
  spendName,
}: {
  loyaltyType: LoyaltyType
  status: LoyaltyStatus
  tiers: LoyaltyTiers
  spendName: string
}) => {
  const dispatch = useAppDispatch()
  const maxThreshold = Math.max(...tiers.map((i) => parseFloat(i.threshold)))
  const total = maxThreshold * 1.1
  const adjustedTiers = tiers.map((i) => ({
    ...i,
    percentage: parseFloat(
      ((parseFloat(i.threshold) / total) * 100).toFixed(5)
    ),
  }))
  const progress = parseFloat(status.progress)
  const progressPercentage = Math.min((progress / total) * 100, 100)
  const style = { width: `${progressPercentage || 0}%` }
  const currentTier = tiers.find((i) => i.is_current_tier)

  const showTier = (
    evt: React.MouseEvent<HTMLButtonElement>,
    tier: LoyaltyTier
  ) => {
    evt.preventDefault()
    dispatch(openModal({ type: 'loyaltyTier', args: { tier } }))
  }

  return (
    <ProgressView>
      <div style={style}>
        <ProgressBarFill />
      </div>
      {adjustedTiers.map((tier) => (
        <ProgressPoint
          key={tier.percentage}
          color={tier.name === currentTier?.name ? tier.hex_code : undefined}
          isFilled={progressPercentage > tier.percentage}
          isZero={tier.percentage === 0}
          style={{
            left: `${tier.percentage.toFixed(5)}%`,
          }}
        >
          <ProgressPointContainer>
            <ProgressPointButton onClick={(evt) => showTier(evt, tier)}>
              {tier.name}
            </ProgressPointButton>
            <ProgressPointHover isLeft={tier.percentage < 50}>
              <span>
                <span>{tier.name}</span>
                <span>Click for details</span>
              </span>
            </ProgressPointHover>
          </ProgressPointContainer>
        </ProgressPoint>
      ))}
      {adjustedTiers.map((tier) => {
        const threshold =
          loyaltyType === 'DOLLARS'
            ? formatDollars(tier.threshold, undefined, 0)
            : roundPoints(tier.threshold)
        const suffix =
          loyaltyType === 'ORDERS' && tier.threshold !== '0.00'
            ? ` ${spendName}`
            : ''
        return (
          <ProgressAmount
            key={tier.percentage}
            style={{ left: `${tier.percentage.toFixed(5)}%` }}
            isZero={tier.percentage === 0}
          >
            <Heading size="small">{tier.name}</Heading>
            <Body size="xSmall">
              {threshold} {suffix}
            </Body>
          </ProgressAmount>
        )
      })}
    </ProgressView>
  )
}

export default ProgressBar
