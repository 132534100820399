import { useEffect, useCallback } from 'react'
import { FormFieldType } from '@open-tender/types'
import { useClaimRewardCodeForm } from '@open-tender/utils'
import {
  claimRewardCode,
  closeModal,
  fetchCustomerRewards,
  resetRewardCode,
  selectRewardCode,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonSubmit, FormError, FormInputs, FormSubmit } from 'components'

const ClaimRewardCodeForm = ({
  windowRef,
}: {
  windowRef: React.RefObject<HTMLDivElement>
}) => {
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(selectRewardCode)
  const callback = useCallback(() => {
    dispatch(closeModal())

    dispatch(fetchCustomerRewards())
  }, [dispatch])

  const claim = (rewardCode: string) =>
    dispatch(claimRewardCode({ rewardCode: rewardCode, callback }))

  const {
    submitRef,
    fields,
    data,
    errors,
    submitting,
    handleChange,
    handleSubmit,
  } = useClaimRewardCodeForm(loading, error, claim, callback)

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  useEffect(() => {
    if (error && windowRef.current) windowRef.current.scrollTop = 0
  }, [error, windowRef])

  useEffect(() => {
    return () => {
      dispatch(resetRewardCode())
    }
  }, [dispatch])

  return (
    <form id="claim-reward-code-form" onSubmit={handleSubmit} noValidate>
      <FormError errMsg={errors.form} style={{ margin: '0 0 2rem' }} />
      <FormInputs
        fields={fields}
        data={data}
        onChange={onChange}
        errors={errors}
      />
      <FormSubmit>
        <ButtonSubmit
          submitRef={submitRef}
          submitting={submitting || !data.reward_code}
        >
          {submitting ? 'Claiming...' : 'Claim'}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default ClaimRewardCodeForm
