import { Gift } from 'components/icons'
import Button from 'components/Button'
import { isMobileOnly } from 'react-device-detect'
import React from 'react'
import styled from '@emotion/styled'
import { openModal, useAppDispatch } from '@open-tender/cloud'

const StyledClaimRewardCode = styled(Button)`
  padding: 0.8rem 1.2rem;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  font-weight: 600;
  border: 1px solid ${(props) => props.theme.colors.border};
  margin-right: 20px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    text-align: center;
    margin: 0 auto;
  }
`

const ClaimRewardCodeButton = () => {
  const dispatch = useAppDispatch()

  return (
    <StyledClaimRewardCode
      icon={<Gift size={isMobileOnly ? 17 : 19} />}
      color="header"
      size="header"
      onClick={() => dispatch(openModal({ type: 'claimRewardCode' }))}
    >
      Claim Reward Code
    </StyledClaimRewardCode>
  )
}

export default ClaimRewardCodeButton
