import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  fetchCustomerTpls,
  selectBrand,
  selectContent,
  selectCustomer,
  selectCustomerTpls,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Content,
  HeaderDefault,
  Loading,
  Main,
  PageContainer,
  PageContent,
  PageTitle,
  PointsShopReward,
} from 'components'
import {
  PointsShopRewards,
  PointsShopRewardsContainer,
  PointsShopRewardsReward,
} from './PointsShop.styled'
import { usePointsShop, useTplsPointsShop } from 'hooks'
import ThirdPartyLoyaltySummary from 'components/ThirdPartyLoyalty/ThirdPartyLoyaltySummary'
import styled from '@emotion/styled'
import PointsShopBalance from './PointsShopBalance'

const TPLSSummaryContainer = styled.div`
  margin: 2rem 0;
`
const PointsShop = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { auth } = useAppSelector(selectCustomer)
  const { tpls } = useAppSelector(selectBrand) || {}
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const { pointsShop: config } = useAppSelector(selectContent) || {}
  const { tpls: tplsData } = useAppSelector(selectCustomerTpls)

  useEffect(() => {
    if (!auth) return navigate('/')
  }, [auth, navigate])

  const hasTplPointsShop =
    tpls === 'COMO' || tpls === 'PUNCHH' || tpls === 'SPARKFLY'
  const usePointsShopHook = hasTplPointsShop ? useTplsPointsShop : usePointsShop

  const { rewards, pointsBalance, hasPointsRewards, isLoading } =
    usePointsShopHook()

  const rewardsSorted = [...rewards].sort(
    (a, b) => (a.points || 0) - (b.points || 0)
  )

  useEffect(() => {
    dispatch(fetchCustomerTpls())
  }, [dispatch])

  return (
    <>
      <Helmet>
        <title>
          {config?.title} | {siteTitle}
        </title>
      </Helmet>
      <Content>
        <HeaderDefault />
        <Main>
          <PageContainer>
            <PageTitle {...config}>
              {tpls !== 'SPARKFLY' && (
                <PointsShopBalance
                  pointsBalance={pointsBalance}
                  isLoading={isLoading}
                />
              )}
              <TPLSSummaryContainer>
                {tplsData && (
                  <ThirdPartyLoyaltySummary
                    isAccount={false}
                    isSimple={tpls === 'SPARKFLY'}
                    {...tplsData}
                  />
                )}
              </TPLSSummaryContainer>
            </PageTitle>
            <PageContent style={{ maxWidth: '99rem' }}>
              {hasPointsRewards ? (
                <PointsShopRewards>
                  <PointsShopRewardsContainer>
                    {rewardsSorted.map((reward) => (
                      <PointsShopRewardsReward key={reward.id}>
                        <PointsShopReward
                          reward={reward}
                          pointsBalance={pointsBalance}
                        />
                      </PointsShopRewardsReward>
                    ))}
                  </PointsShopRewardsContainer>
                </PointsShopRewards>
              ) : isLoading ? (
                <Loading text="Retrieving today's offers..." />
              ) : (
                <p>
                  We're not featuring any offers today. Please check back soon!
                </p>
              )}
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default PointsShop
