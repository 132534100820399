import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormFieldType } from '@open-tender/types'
import { useLoginForm } from '@open-tender/utils'
import {
  closeModal,
  loginCustomer,
  loginCustomerThanx,
  resetLoginError,
  resetOneTimePasscode,
  selectBrand,
  selectCustomer,
  selectOneTimePasscode,
  selectRecaptcha,
  signInCustomer,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  ButtonSubmit,
  FormError,
  FormInputs,
  FormRecaptcha,
  FormSubmit,
  Recaptcha,
} from 'components'
import { ThirdPartyLoyaltySignUpMessage } from 'components/ThirdPartyLoyalty'
import { sendLogin } from 'app/analytics'

const LoginForm = ({ callback }: { callback?: () => void }) => {
  const dispatch = useAppDispatch()
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
  const { has_thanx, tpls, use_enterprise_recaptcha } =
    useAppSelector(selectBrand) || {}
  const hasPunchh = tpls === 'PUNCHH'
  const customer = useAppSelector(selectCustomer)
  const signIn = useAppSelector(selectOneTimePasscode)
  const { login: includeRecaptcha } = useAppSelector(selectRecaptcha) || {}

  const isSubmitDisabled =
    includeRecaptcha && !use_enterprise_recaptcha && !recaptchaToken

  const loading = useMemo(
    () => (hasPunchh ? signIn.loading : customer.loading),
    [hasPunchh, customer.loading, signIn.loading]
  )
  const error = useMemo(
    () => (hasPunchh ? signIn.error : customer.error),
    [hasPunchh, customer.error, signIn.error]
  )
  const showSignUpMessage =
    hasPunchh && error?.code === 'MEMBER_SIGNUP_REQUIRED'

  const loginDefault = useCallback(
    (email: string, password?: string, recaptcha_token?: string) =>
      dispatch(
        loginCustomer({ email, password: password as string, recaptcha_token })
      ),
    [dispatch]
  )
  const loginThanx = useCallback(
    (email: string, _?: string, recaptcha_token?: string) =>
      dispatch(loginCustomerThanx({ email, recaptcha_token })),
    [dispatch]
  )
  const loginTpls = useCallback(
    (email: string, password?: string, recaptcha_token?: string) =>
      dispatch(signInCustomer({ data: { email, password, recaptcha_token } })),
    [dispatch]
  )
  const login = has_thanx ? loginThanx : hasPunchh ? loginTpls : loginDefault

  const loginCallback = () => {
    sendLogin(data.email)
    callback?.() || dispatch(closeModal())
  }

  const {
    submitRef,
    recaptchaRef,
    fields,
    data,
    errors,
    submitting,
    handleChange,
    handleSubmit,
  } = useLoginForm(
    loading,
    error,
    login,
    includeRecaptcha ?? false,
    loginCallback,
    !has_thanx
  )
  const errMsg = error?.message || errors.form || ''

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  useEffect(() => {
    if (error) {
      setRecaptchaToken(null)
    }
  }, [error])

  useEffect(() => {
    dispatch(resetLoginError())
    dispatch(resetOneTimePasscode())
    return () => {
      dispatch(resetLoginError())
      dispatch(resetOneTimePasscode())
    }
  }, [dispatch])

  return (
    <form id="login-form" onSubmit={handleSubmit} noValidate>
      {showSignUpMessage ? (
        <ThirdPartyLoyaltySignUpMessage />
      ) : (
        <FormError errMsg={errMsg} style={{ margin: '0 0 2rem' }} />
      )}
      <FormInputs
        fields={fields}
        data={data}
        onChange={onChange}
        errors={errors}
      />
      {includeRecaptcha && (
        <FormRecaptcha>
          <Recaptcha recaptchaRef={recaptchaRef} onChange={setRecaptchaToken} />
        </FormRecaptcha>
      )}
      <FormSubmit>
        <ButtonSubmit
          disabled={isSubmitDisabled}
          submitRef={submitRef}
          submitting={submitting}
        >
          {submitting ? 'Submitting...' : 'Submit'}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default LoginForm
