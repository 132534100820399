import { CartItemGroup, CartItemOption, CartLevels } from '@open-tender/types'
import { formatDollars, formatQuantity } from '@open-tender/utils'
import {
  selectDisplaySettings,
  selectSelectedAllergenNames,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Checkmark,
  Collapsible,
  Count,
  MenuItemTagsAllergens,
} from 'components'
import { AlertCircle, X } from 'components/icons'
import MenuItemGroups from '../MenuItemGroups'
import MenuItemOptionPrice from '../../MenuItemOptionPrice'
import {
  MenuItemOptionImage,
  MenuItemOptionOverlay,
  MenuItemOptionOverlayAlert,
  MenuItemOptionSquareButton,
  MenuItemOptionSquareCount,
  MenuItemOptionSquareDesc,
  MenuItemOptionSquareImageContainer,
  MenuItemOptionSquareName,
  MenuItemOptionSquareNested,
  MenuItemOptionSquareRemove,
  MenuItemOptionSquareRemoveIcon,
  MenuItemOptionSquareSoldOut,
  MenuItemOptionSquareText,
  MenuItemOptionSquareView,
} from './MenuItemOptionSquare.styled'
import { isMobile } from 'react-device-detect'
import { useSuspendUntil } from 'utils/helpers'

const MenuItemOptionAllergenOverlay = () => (
  <MenuItemOptionOverlay overlay="alert">
    <MenuItemOptionOverlayAlert>
      <AlertCircle />
    </MenuItemOptionOverlayAlert>
  </MenuItemOptionOverlay>
)

const MenuItemOptionSquare = ({
  group,
  option,
  incrementOption,
  decrementOption,
  levels = [],
  toggleOption,
  setOptionQuantity,
}: {
  group: CartItemGroup
  option: CartItemOption
  levels: CartLevels
  incrementOption: (levels: CartLevels) => void
  decrementOption: (levels: CartLevels) => void
  toggleOption: (levels: CartLevels) => void
  setOptionQuantity: (levels: CartLevels, quantity: number) => void
}) => {
  const {
    calories: showCals,
    tags: showTags,
    allergens: showAllergens,
    modifierImage: showImage = true,
  } = useAppSelector(selectDisplaySettings) || {}
  const allergenAlerts = useAppSelector(selectSelectedAllergenNames)
  const { name, description, imageUrl, isSoldOut, suspend_until } = option
  const soldOutMsg = useSuspendUntil(suspend_until) ?? 'Out of stock'
  const bgStyle = imageUrl ? { backgroundImage: `url(${imageUrl}` } : undefined
  const groupAtMax = group.max !== 0 && group.quantity === group.max
  const optionAtMax = option.max !== 0 && option.quantity === option.max
  const oneAtMax = option.quantity === 1 && option.max === 1
  const incrementDisabled = groupAtMax || optionAtMax
  const hidePrice =
    group.included !== 0 &&
    (group.included === group.max ||
      (group.quantity ?? 0) < (group.included ?? 0))
  const price = hidePrice ? null : formatDollars(`${option.price}`)
  const cals = showCals ? option.cals : null
  const allergens = showAllergens ? option.allergens : []
  const tags = showTags ? option.tags : []
  const disabled = (incrementDisabled && !oneAtMax) || isSoldOut
  const itemAllergens =
    allergens && allergens.length
      ? allergens.filter((allergen) => allergenAlerts.includes(allergen))
      : []
  const allergenAlert = itemAllergens.length > 0
  const optionGroups =
    option.groups && option.groups.length ? option.groups : null
  const showGroups = option.quantity >= 1 && optionGroups ? true : false
  const updatedLevels = [...levels, [group.id, option.id]]
  const classSelected = option.quantity > 0 ? 'selected' : ''
  const classHover = isMobile || disabled ? '' : ' show-hover'
  const className = classSelected + classHover

  const increment = () => {
    incrementOption(updatedLevels)
  }

  const decrement = () => {
    decrementOption(updatedLevels)
  }

  const onClick =
    option.quantity === 1 && option.max === 1 ? decrement : increment

  return (
    <MenuItemOptionSquareView showGroups={showGroups}>
      {(option.quantity ?? 0) > 0 && (
        <>
          {option.max === 1 ? (
            <MenuItemOptionSquareCount>
              <Checkmark />
            </MenuItemOptionSquareCount>
          ) : (
            <MenuItemOptionSquareCount>
              <Count count={formatQuantity(`${option.quantity}`)} />
            </MenuItemOptionSquareCount>
          )}

          <MenuItemOptionSquareRemove onClick={decrement} className={className}>
            <MenuItemOptionSquareRemoveIcon>
              <X size={18} strokeWidth={2} />
            </MenuItemOptionSquareRemoveIcon>
          </MenuItemOptionSquareRemove>
        </>
      )}
      <MenuItemOptionSquareButton
        onClick={onClick}
        disabled={disabled}
        showGroups={showGroups}
        className={className}
      >
        {showImage && (
          <MenuItemOptionSquareImageContainer>
            <MenuItemOptionImage style={bgStyle}>
              {allergenAlert ? <MenuItemOptionAllergenOverlay /> : null}
            </MenuItemOptionImage>
          </MenuItemOptionSquareImageContainer>
        )}
        <MenuItemOptionSquareText showImage={showImage}>
          <MenuItemOptionSquareName size="xSmall">
            {name}
          </MenuItemOptionSquareName>
          {description ? (
            <MenuItemOptionSquareDesc size="xxSmall">
              {description}
            </MenuItemOptionSquareDesc>
          ) : null}
          {isSoldOut ? (
            <MenuItemOptionSquareSoldOut size="xSmall">
              {soldOutMsg}
            </MenuItemOptionSquareSoldOut>
          ) : (
            <MenuItemOptionPrice
              price={price}
              cals={cals}
              style={{ marginTop: '0.5rem' }}
            />
          )}
          <MenuItemTagsAllergens
            tags={tags}
            allergens={allergens}
            isCentered={true}
            style={{ marginTop: '0.5rem' }}
          />
        </MenuItemOptionSquareText>
      </MenuItemOptionSquareButton>
      {optionGroups ? (
        <Collapsible show={showGroups}>
          <MenuItemOptionSquareNested>
            <MenuItemGroups
              groups={option.groups}
              levels={updatedLevels}
              toggleOption={toggleOption}
              incrementOption={incrementOption}
              decrementOption={decrementOption}
              setOptionQuantity={setOptionQuantity}
              isChild={true}
            />
          </MenuItemOptionSquareNested>
        </Collapsible>
      ) : null}
    </MenuItemOptionSquareView>
  )
}

export default MenuItemOptionSquare
