import { ModalContent, ModalView } from 'components'
import ClaimRewardCodeForm from 'components/forms/ClaimRewardCodeForm'

const ClaimRewardCode = ({
  windowRef,
}: {
  windowRef: React.RefObject<HTMLDivElement>
}) => {
  return (
    <ModalView>
      <ModalContent title="Enter code to claim reward">
        <ClaimRewardCodeForm windowRef={windowRef} />
      </ModalContent>
    </ModalView>
  )
}

export default ClaimRewardCode
