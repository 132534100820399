import { CartItemOption } from '@open-tender/types'
import {
  PizzaBuilderToppingImage,
  PizzaBuilderToppingImageContainer,
  PizzaBuilderToppingView,
} from './PizzaBuilderTopping.styled'
import React, { useMemo } from 'react'
import { Amount, Coverage } from './types'
import {
  getCoverageMinMaxMap,
  selectItemsWithEqualDistribution,
  squareSizeMap,
} from './utils'
import range from 'lodash/range'

const PizzaBuilderTopping = ({
  option,
  index,
  pizzaSize,
  isRectangular = false,
}: {
  option: CartItemOption
  index: number
  pizzaSize: number
  isRectangular?: boolean
}) => {
  const { groups } = option

  const coverageGroup = groups.find((group) => group.name === 'Coverage')
  const coverageOption = coverageGroup?.options.find(
    (option) => option.quantity === 1
  )
  const coverage = (coverageOption?.slug as Coverage | undefined) ?? 'whole'

  const amountGroup = groups.find((group) => group.name === 'Amount')
  const amountOption = amountGroup?.options.find(
    (option) => option.quantity === 1
  )
  const amount = (amountOption?.slug as Amount | undefined) ?? 'normal'

  const squareSize = useMemo<number>(() => squareSizeMap[amount], [amount])
  const cellsCount = useMemo<number>(
    () => Math.pow(squareSize, 2),
    [squareSize]
  )
  const cellsArray = useMemo<number[]>(() => range(cellsCount), [cellsCount])

  const visiblePieces = useMemo<number[]>(() => {
    const { max, min } = getCoverageMinMaxMap(cellsCount)[coverage]
    return selectItemsWithEqualDistribution(min, max, cellsCount, coverage)
  }, [coverage, cellsCount])

  return (
    <PizzaBuilderToppingView isRectangular={isRectangular} coverage={coverage}>
      {cellsArray.map((cell) => (
        <PizzaBuilderToppingImageContainer
          squareSize={squareSize}
          index={index}
          key={cell}
        >
          {visiblePieces.includes(cell) && !!option.imageUrl && (
            <PizzaBuilderToppingImage
              pizzaSize={pizzaSize}
              src={option.imageUrl}
            />
          )}
        </PizzaBuilderToppingImageContainer>
      ))}
    </PizzaBuilderToppingView>
  )
}

export default PizzaBuilderTopping
