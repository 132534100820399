import React, { useCallback, useState } from 'react'
import { useTheme } from '@emotion/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import {
  CartItem,
  MenuItemFavorite,
  MenuItem as MenuItemType,
} from '@open-tender/types'
import {
  makeOrderItem,
  rehydrateOrderItem,
  slugify,
  useOrderItem,
} from '@open-tender/utils'
import {
  addItemToCart,
  openModal,
  selectCartCounts,
  selectDisplaySettings,
  selectMenu,
  selectMenuSlug,
  selectPointsProgram,
  setCurrentItem,
  showNotification,
  setMenuPath,
  toggleSidebarModal,
  useAppDispatch,
  useAppSelector,
  setOffsetTop,
  selectSelectedAllergenNames,
  selectSelectedTagNames,
  selectOrder,
} from '@open-tender/cloud'
import {
  Button,
  MenuItemButton,
  MenuItemOverlay,
  MenuItemTagAlert,
} from 'components'
import MenuItemCount from '../MenuItemCount'
import {
  MenuItemButtons,
  MenuItemButtonsAdd,
  MenuItemButtonsContainer,
  MenuItemButtonsCustomize,
  MenuItemButtonsQuickAdd,
  MenuItemButtonsWarning,
  MenuItemView,
  MenuItemWrapper,
} from './MenuItem.styled'
import { Plus } from 'components/icons'
import MenuItemUpsell from '../../../MenuItem/MenuItemUpsell'

const MenuItem = ({
  item,
  favorite,
  displayOnly = false,
  addCallback,
}: {
  item: MenuItemType
  favorite?: MenuItemFavorite
  displayOnly?: boolean
  addCallback?: () => void
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [clicked, setClicked] = useState(false)
  const [showUpsell, setShowUpsell] = useState<boolean>(false)
  const [isUpsellMounted, setIsUpsellMounted] = useState<boolean>(false)
  const { pathname } = useLocation()
  const { requestedAt } = useAppSelector(selectOrder)
  const menuSlug = useAppSelector(selectMenuSlug)
  const cartCounts = useAppSelector(selectCartCounts)
  const { soldOut } = useAppSelector(selectMenu)
  const pointsProgram = useAppSelector(selectPointsProgram)
  const hasPoints = !!pointsProgram
  const theme = useTheme()
  const { buttons, content, image } = theme.menuItems
  const buttonsDisplay = isMobile ? buttons.displayMobile : buttons.display
  const showButtons = buttonsDisplay === 'SHOW'
  const showQuickAdd = buttonsDisplay === 'MINIMAL'
  const quickAddSize = isMobile ? 20 : 24
  const showDesc = isMobile ? content.descriptionMobile : content.description
  const showImage = image.position !== 'HIDDEN'
  const displaySettings = useAppSelector(selectDisplaySettings)
  const allergenAlerts = useAppSelector(selectSelectedAllergenNames)
  const tagAlerts = useAppSelector(selectSelectedTagNames)
  const {
    calories: showCals = false,
    tags: showTags = false,
    allergens: showAllergens = false,
  } = displaySettings || {}
  const orderItem = favorite
    ? {
        ...rehydrateOrderItem(item, favorite.item, soldOut, requestedAt),
        index: -1,
      }
    : makeOrderItem(item, undefined, soldOut, undefined, hasPoints, requestedAt)

  const hasUpsell = item.upsell_items.length > 0

  const {
    name,
    imageUrl,
    displayDesc,
    displayTags,
    displayAllergens,
    displayPrice,
    displayCals,
    isIncomplete,
    isSoldOut,
    allergenAlert,
    sizeOnly,
    cartCount,
    tagAlert,
  } = useOrderItem(
    orderItem as CartItem,
    favorite,
    allergenAlerts,
    cartCounts,
    showCals,
    showTags,
    showAllergens,
    showDesc,
    tagAlerts
  )
  const { builderType } = displaySettings || {}
  const adjBuilderType = isMobile ? 'PAGE' : builderType
  const addDisabled = isIncomplete || isSoldOut
  const customizeIsPrimary = addDisabled && !isSoldOut
  const disabled = isSoldOut || displayOnly
  const showHover = !disabled && !isMobile

  const view = () => {
    if (!isSoldOut) {
      dispatch(setMenuPath(pathname || menuSlug))
      dispatch(setCurrentItem(orderItem))
      if (adjBuilderType?.includes('PAGE')) {
        const scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop || null
        dispatch(setOffsetTop(scrollTop))
        navigate(`${menuSlug}/item/${slugify(name ?? '')}`)
      } else if (adjBuilderType === 'SIDEBAR') {
        dispatch(toggleSidebarModal())
      } else {
        dispatch(openModal({ type: 'item', args: { focusFirst: true } }))
      }
    }
  }

  const handleOnShowUpsells = () => {
    setIsUpsellMounted(true)
    setTimeout(() => setShowUpsell(true))
  }

  const handleOnHideUpsells = useCallback(() => {
    setShowUpsell(false)
    setTimeout(() => setIsUpsellMounted(false), 250)
  }, [])

  const add = () => {
    if (!isSoldOut) {
      const cartItem = { ...orderItem } as CartItem
      if (cartItem.index === -1) delete cartItem.index
      dispatch(addItemToCart(cartItem))
      dispatch(showNotification(`${name} added to cart!`))
      if (addCallback) addCallback()
      if (hasUpsell) handleOnShowUpsells()
    }
  }

  const imageOverlay = showImage ? (
    <MenuItemOverlay
      isSoldOut={isSoldOut}
      suspendedUntil={item.suspend_until}
      allergenAlert={allergenAlert ?? undefined}
      tagAlert={tagAlert ?? undefined}
    />
  ) : null

  return (
    <>
      <MenuItemWrapper>
        {cartCount > 0 && (
          <MenuItemCount>
            <span>{cartCount}</span>
          </MenuItemCount>
        )}
        {!showImage ? (
          <MenuItemTagAlert
            isSoldOut={isSoldOut}
            allergenAlert={allergenAlert ?? ''}
          />
        ) : null}
        <MenuItemView className={showHover ? 'show-hover' : ''}>
          {showQuickAdd && !addDisabled ? (
            <MenuItemButtonsQuickAdd>
              <Button
                onClick={isIncomplete ? () => setClicked(true) : add}
                size="small"
                color={buttons.color}
                disabled={addDisabled}
              >
                <Plus size={quickAddSize} />
              </Button>
            </MenuItemButtonsQuickAdd>
          ) : null}
          <MenuItemButton
            onClick={view}
            disabled={disabled}
            imageUrl={imageUrl ?? ''}
            imageOverlay={imageOverlay}
            name={item.name ?? ''}
            desc={displayDesc}
            price={displayPrice}
            cals={displayCals ?? ''}
            tags={displayTags}
            allergens={displayAllergens}
          />
          {showButtons && (
            <MenuItemButtons>
              {clicked && (
                <MenuItemButtonsWarning>
                  Item requires customization. Tap "Customize".
                </MenuItemButtonsWarning>
              )}
              <MenuItemButtonsContainer>
                <MenuItemButtonsAdd disabled={isSoldOut}>
                  <Button
                    onClick={isIncomplete ? view : add}
                    size="small"
                    color={buttons.color}
                    disabled={isSoldOut}
                  >
                    Add To Order
                  </Button>
                </MenuItemButtonsAdd>
                <MenuItemButtonsCustomize
                  customizeIsPrimary={customizeIsPrimary}
                >
                  <Button
                    onClick={view}
                    disabled={isSoldOut}
                    size="small"
                    color="secondary"
                  >
                    {sizeOnly ? 'Choose Size' : 'Customize'}
                  </Button>
                </MenuItemButtonsCustomize>
              </MenuItemButtonsContainer>
            </MenuItemButtons>
          )}
        </MenuItemView>
      </MenuItemWrapper>
      {isUpsellMounted && (
        <MenuItemUpsell
          initiatingMenuItem={orderItem as CartItem}
          showUpsell={showUpsell}
          upsellItemIds={item.upsell_items}
          cancel={handleOnHideUpsells}
        />
      )}
    </>
  )
}

export default MenuItem
