import styled from '@emotion/styled'
import {
  fetchCustomerTpls,
  retroClaimTplsPointsShop,
  selectBrand,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Input, Loading, PageTitle, FormWrapper, Button } from 'components'
import { usePointsShop, useTplsPointsShop } from 'hooks'
import React, { useEffect, useState } from 'react'

const LoadingContainer = styled.div`
  height: 100px;
`
const ClaimPointsForm = ({
  callback,
}: {
  callback?: (claimedPoints: number) => void
}) => {
  const dispatch = useAppDispatch()
  const [receiptId, setReceiptId] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { tpls } = useAppSelector(selectBrand) || {}
  const hasTplPointsShop =
    tpls === 'COMO' || tpls === 'PUNCHH' || tpls === 'SPARKFLY'
  const usePointsShopHook = hasTplPointsShop ? useTplsPointsShop : usePointsShop
  const { pointsBalance } = usePointsShopHook()

  useEffect(() => {
    dispatch(fetchCustomerTpls())
  }, [dispatch])

  const handleCallback = async () => {
    const currentPoints = pointsBalance
    const getLatestPoints = await dispatch(fetchCustomerTpls())
    if (
      getLatestPoints.type.endsWith('fulfilled') &&
      getLatestPoints.payload &&
      'points' in getLatestPoints.payload
    ) {
      const newBalance = getLatestPoints.payload?.points?.balance || 0
      const claimedPoints = newBalance - (currentPoints || 0)
      callback?.(claimedPoints)
    }
  }

  const handleClaim = async () => {
    setLoading(true)
    setError('')
    try {
      const response = await dispatch(retroClaimTplsPointsShop(receiptId))
      if (response.type.endsWith('fulfilled')) {
        setReceiptId('')
        await handleCallback()
      } else {
        setError('Failed to claim points')
      }
    } catch {
      setError('Failed to claim points')
    } finally {
      setLoading(false)
    }
  }

  const title = 'Claim Points'
  const subtitle =
    "Enter the 'ReceiptID' from your printed receipt to claim missed points."
  return (
    <FormWrapper>
      <PageTitle title={title} subtitle={subtitle} />
      {loading ? (
        <LoadingContainer>
          <Loading text="Claiming points..." />
        </LoadingContainer>
      ) : (
        <>
          <Input
            name="receiptId"
            type="text"
            label="Receipt ID"
            value={receiptId}
            onChange={(event) => setReceiptId(event.target.value)}
            error={error}
          />

          <Button onClick={handleClaim}>Claim</Button>
        </>
      )}
    </FormWrapper>
  )
}

export default ClaimPointsForm
