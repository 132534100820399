import { useTheme } from '@emotion/react'
import * as Iconoir from 'iconoir-react'

export interface IconProps {
  size?: number
  strokeWidth?: number
  color?: string
  strokeColor?: string
  fill?: string
}

const makeIconProps = (props: IconProps) => {
  const { size = 20, ...rest } = props
  return { width: size, height: size, ...rest }
}

export const AlertCircle = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.WarningCircle strokeWidth={icons.strokeWidth} {...props} />
}

export const ArrowLeft = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.ArrowLeft strokeWidth={icons.strokeWidth} {...props} />
}

export const ArrowRight = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.ArrowRight strokeWidth={icons.strokeWidth} {...props} />
}

export const Award = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Medal strokeWidth={icons.strokeWidth} {...props} />
}

export const Check = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Check strokeWidth={icons.strokeWidth} {...props} />
}
export const CheckCircleSolid = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.CheckCircleSolid strokeWidth={icons.strokeWidth} {...props} />
}

export const ChevronDown = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.ArrowDown strokeWidth={icons.strokeWidth} {...props} />
}

export const ChevronLeft = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.ArrowLeft strokeWidth={icons.strokeWidth} {...props} />
}

export const ChevronRight = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.ArrowRight strokeWidth={icons.strokeWidth} {...props} />
}

export const ChevronUp = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.ArrowUp strokeWidth={icons.strokeWidth} {...props} />
}

export const Clipboard = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.PasteClipboard strokeWidth={icons.strokeWidth} {...props} />
}

export const Clock = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Clock strokeWidth={icons.strokeWidth} {...props} />
}

export const Coffee = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.CoffeeCup strokeWidth={icons.strokeWidth} {...props} />
}

export const CornerDownRight = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.ArrowDownRight strokeWidth={icons.strokeWidth} {...props} />
}

export const CreditCard = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.CreditCard strokeWidth={icons.strokeWidth} {...props} />
}

export const DollarSign = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Dollar strokeWidth={icons.strokeWidth} {...props} />
}

export const Edit = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Edit strokeWidth={icons.strokeWidth} {...props} />
}

export const ExternalLink = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Link strokeWidth={icons.strokeWidth} {...props} />
}

export const Flag = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.DashFlag strokeWidth={icons.strokeWidth} {...props} />
}

export const Gift = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Gift strokeWidth={icons.strokeWidth} {...props} />
}

export const Grid = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.ViewGrid strokeWidth={icons.strokeWidth} {...props} />
}

export const Heart = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Heart strokeWidth={icons.strokeWidth} {...props} />
}

export const Home = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Home strokeWidth={icons.strokeWidth} {...props} />
}

export const Info = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.InfoCircle strokeWidth={icons.strokeWidth} {...props} />
}

export const Lock = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Lock strokeWidth={icons.strokeWidth} {...props} />
}

export const LogOut = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.LogOut strokeWidth={icons.strokeWidth} {...props} />
}

export const Mail = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Mail strokeWidth={icons.strokeWidth} {...props} />
}

export const Map = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Map strokeWidth={icons.strokeWidth} {...props} />
}

export const MapsArrowDiagonal = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return (
    <Iconoir.MapsArrowDiagonal strokeWidth={icons.strokeWidth} {...props} />
  )
}

export const MapPin = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.MapPin strokeWidth={icons.strokeWidth} {...props} />
}

export const Menu = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Menu strokeWidth={icons.strokeWidth} {...props} />
}

export const Minus = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Minus strokeWidth={icons.strokeWidth} {...props} />
}

export const MinusCircle = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.MinusCircle strokeWidth={icons.strokeWidth} {...props} />
}

export const Navigation = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Navigator strokeWidth={icons.strokeWidth} {...props} />
}

export const Phone = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Phone strokeWidth={icons.strokeWidth} {...props} />
}

export const Plus = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Plus strokeWidth={icons.strokeWidth} {...props} />
}

export const PlusCircle = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.PlusCircle strokeWidth={icons.strokeWidth} {...props} />
}

export const ScanBarcode = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.ScanBarcode strokeWidth={icons.strokeWidth} {...props} />
}

export const ShoppingBag = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.ShoppingBag strokeWidth={icons.strokeWidth} {...props} />
}

export const ShoppingCart = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Cart strokeWidth={icons.strokeWidth} {...props} />
}

export const Slash = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Slash strokeWidth={icons.strokeWidth} {...props} />
}

export const Sliders = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Filter strokeWidth={icons.strokeWidth} {...props} />
}

export const Star = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Star strokeWidth={icons.strokeWidth} {...props} />
}

export const Tag = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.TerminalTag strokeWidth={icons.strokeWidth} {...props} />
}

export const Truck = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Truck strokeWidth={icons.strokeWidth} {...props} />
}

export const User = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.User strokeWidth={icons.strokeWidth} {...props} />
}

export const Users = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.User strokeWidth={icons.strokeWidth} {...props} />
}

export const UserPlus = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.UserPlus strokeWidth={icons.strokeWidth} {...props} />
}

export const XCircle = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.XmarkCircle strokeWidth={icons.strokeWidth} {...props} />
}

export const X = (iconProps: IconProps) => {
  const { icons } = useTheme()
  const props = makeIconProps(iconProps)
  return <Iconoir.Xmark strokeWidth={icons.strokeWidth} {...props} />
}
