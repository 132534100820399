import { useEffect, useState } from 'react'
import { isBrowser } from 'react-device-detect'
import { scroller, Element } from 'react-scroll'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { slugify } from '@open-tender/utils'
import {
  fetchAllergens,
  fetchMenu,
  selectAnnouncementsPage,
  selectBrand,
  selectContentSection,
  selectMenu,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  BackgroundContent,
  Button,
  Content,
  Main,
  HeaderSite,
  PageHero,
  PageIntro,
} from 'components'
import MenuSiteCategory from './MenuSiteCategory'
import { useAnnouncementPage } from 'hooks'

const MenuSiteView = styled.div``

const MenuSiteMenu = styled.div`
  max-width: 128rem;
  margin: 0 auto;
`

const MenuSite = () => {
  const dispatch = useAppDispatch()
  const { colors } = useTheme()
  const { olo_id, title: siteTitle } = useAppSelector(selectBrand) || {}
  const [revenueCenterId] = useState(olo_id)
  const { background, mobile, title, subtitle, content } =
    useAppSelector(selectContentSection('menuSite')) || {}
  const serviceType = 'PICKUP'
  const requestedAt = 'asap'
  // const geoLatLng = useAppSelector(selectGeoLatLng)
  const { categories } = useAppSelector(selectMenu)
  const announcements = useAppSelector(selectAnnouncementsPage('MENU'))

  const scrollToMenu = () => {
    scroller.scrollTo('menuSite', {
      duration: 500,
      smooth: true,
      offset: -120,
    })
  }

  useEffect(() => {
    if (revenueCenterId) {
      dispatch(fetchAllergens())
      dispatch(fetchMenu({ revenueCenterId, serviceType, requestedAt }))
    }
  }, [revenueCenterId, serviceType, requestedAt, dispatch])

  useAnnouncementPage('MENU')

  return (
    <>
      <Helmet>
        <title>Menu | {siteTitle}</title>
      </Helmet>
      <Content>
        <HeaderSite />
        <Main style={{ paddingTop: '0' }}>
          <PageHero
            announcements={announcements}
            imageUrl={isBrowser ? background : mobile}
          >
            <BackgroundContent
              title={title ?? ''}
              subtitle={subtitle ?? ''}
              title_color={colors.light}
              subtitle_color={colors.light}
              vertical="BOTTOM"
              horizontal="LEFT"
            >
              <Button onClick={scrollToMenu} size="big" color="light">
                Browse The Menu
              </Button>
            </BackgroundContent>
          </PageHero>
          <MenuSiteView>
            <PageIntro content={content} />
            <Element name="menuSite">
              <MenuSiteMenu>
                {categories.map((category) => (
                  <div key={category.id} id={slugify(category.name)}>
                    <MenuSiteCategory category={category} />
                    {category.children.map((category) => (
                      <MenuSiteCategory
                        key={category.id}
                        category={category}
                        isChild={true}
                      />
                    ))}
                  </div>
                ))}
              </MenuSiteMenu>
            </Element>
          </MenuSiteView>
        </Main>
      </Content>
    </>
  )
}

export default MenuSite
