import { ReactElement, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { hasEntities } from '@open-tender/utils'
import {
  AnnouncementsState,
  closeModal,
  fetchCustomer,
  fetchCustomerCreditCards,
  fetchCustomerFavorites,
  fetchCustomerGroupOrders,
  fetchCustomerOrders,
  fetchCustomerRewards,
  fetchDeals,
  selectAnnouncementsPage,
  selectBrand,
  selectContentSection,
  selectCustomer,
  selectCustomerGroupOrders,
  selectCustomerOrders,
  selectCustomerRewards,
  selectDeals,
  selectHasAnnouncementsPage,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Background,
  BackgroundImage,
  Content,
  Header,
  HeaderLogo,
  Main,
  Welcome,
  Video,
} from '../..'
import { Cart, NavMenu, User } from '../../buttons'
import AccountButtons from './AccountButtons'
import AccountContent from './AccountContent'
import AccountLoyalty from './AccountLoyalty'
import AccountRewards from './AccountRewards'
import AccountDeals from './AccountDeals'
import AccountGroupOrders from './AccountGroupOrders'
import AccountOrders from './AccountOrders'
import AccountSlider from './AccountSlider'
import { AnnouncementSettings, Discounts, Orders } from '@open-tender/types'
import AccountScan from './AccountScan'
import { useAnnouncementPage } from 'hooks'

export const AccountWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom, 0);
`

export const AccountView = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 0 ${(props) => props.theme.layout.margin};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0 0 10rem;
  }
`

export const AccountMobile = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`

export const AccountHero = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 32rem;
  padding: 0 ${(props) => props.theme.layout.padding};
  margin: ${(props) => props.theme.layout.margin} 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    min-height: 16rem;
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
    margin: 0;
  }

  & > div {
    overflow: hidden;
    border-radius: ${(props) => props.theme.border.radius};
  }

  & > video {
    border-radius: ${(props) => props.theme.border.radius};
  }
`

const Account = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const { auth, profile } = useAppSelector(selectCustomer)
  const token = auth ? auth.access_token : null
  const {
    title: siteTitle,
    has_app,
    has_deals,
    has_rewards,
    has_loyalty,
    has_thanx,
    tpls,
  } = useAppSelector(selectBrand) || {}
  const {
    title,
    subtitle,
    background,
    mobile,
    content,
    videoUrl,
    videoUrlMobile,
    displayLogoMobile,
    displayed: displayedDesktop,
    displayedMobile,
    showFirstName,
    punctuation,
  } = useAppSelector(selectContentSection('account')) || {}
  const showLogo = isMobile ? displayLogoMobile : false
  const firstName = profile ? profile.first_name : null
  const greeting =
    firstName && showFirstName
      ? `${title}, ${firstName}${punctuation}`
      : `${title}${punctuation}`
  const displayed = isMobile ? displayedMobile : displayedDesktop
  const showScan = has_app && has_loyalty && isMobile

  const hasContent = displayed?.includes('CONTENT') && content && content.length
  const contentSection = hasContent
    ? (key: string) => <AccountContent key={key} content={content} />
    : null

  const deals = useAppSelector(selectDeals)
  const hasDeals = has_deals && displayed?.includes('DEALS')
  const displayDeals = hasDeals && hasEntities<Discounts>(deals)
  const dealsSection = displayDeals
    ? (key: string) => <AccountDeals key={key} deals={deals.entities} />
    : null

  const rewards = useAppSelector(selectCustomerRewards)
  const hasRewards = has_rewards && displayed?.includes('REWARDS')
  const displayRewads = hasRewards && hasEntities<Discounts>(rewards)
  const rewardsSection = displayRewads
    ? (key: string) => <AccountRewards key={key} rewards={rewards.entities} />
    : null

  const orders = useAppSelector(selectCustomerOrders)
  const hasOrders = displayed?.includes('ORDERS')
  const displayOrders = hasOrders && hasEntities<Orders>(orders)
  const ordersSection = displayOrders
    ? (key: string) => <AccountOrders key={key} orders={orders.entities} />
    : null

  const groupOrders = useAppSelector(selectCustomerGroupOrders)
  const hasGroupOrders = displayed?.includes('GROUP_ORDERS')
  const displayGroupOrders = hasGroupOrders && hasEntities(groupOrders)
  const groupOrdersSection = displayGroupOrders
    ? (key: string) => (
        <AccountGroupOrders key={key} orders={groupOrders.entities} />
      )
    : null

  // const loyalty = useAppSelector(selectCustomerLoyaltyProgram)
  const hasLoyalty = has_loyalty || has_thanx || tpls
  const displayLoyalty = displayed?.includes('LOYALTY') && hasLoyalty
  const loyaltySection = displayLoyalty
    ? (key: string) => <AccountLoyalty key={key} />
    : null

  const hasHero = isMobile ? mobile || videoUrlMobile : background
  const displayHero = displayed?.includes('HERO') && hasHero
  const heroSection = displayHero
    ? (key: string) => (
        <AccountHero key={key}>
          {videoUrlMobile ? (
            <Video src={videoUrlMobile} poster={mobile} />
          ) : (
            <BackgroundImage imageUrl={mobile} />
          )}
        </AccountHero>
      )
    : null

  const announcements = useAppSelector(selectAnnouncementsPage('ACCOUNT'))
  const hasAnnouncements = useAppSelector(selectHasAnnouncementsPage('ACCOUNT'))
  const displayAnnouncements =
    displayed?.includes('ANNOUNCEMENTS') && hasAnnouncements
  const announcementsSection = displayAnnouncements
    ? (key: string) => (
        <AccountSlider
          announcements={
            announcements as AnnouncementsState & {
              settings: AnnouncementSettings
            }
          }
          key={key}
        />
      )
    : null

  const sections: Record<string, ((key: string) => ReactElement) | null> = {
    CONTENT: contentSection,
    LOYALTY: loyaltySection,
    REWARDS: rewardsSection,
    DEALS: dealsSection,
    ORDERS: ordersSection,
    GROUP_ORDERS: groupOrdersSection,
    HERO: heroSection,
    ANNOUNCEMENTS: announcementsSection,
  }
  const displayedSections = displayed
    ? displayed
        .map((i) => {
          const func = sections[i]
          return func ? func(i) : null
        })
        .filter(Boolean)
    : null

  // const isLoading = checkLoading(deals, rewards, orders, loyalty)

  const buttons = useRef<any>(null)
  const buttonsHeight = buttons.current?.offsetHeight || 100
  const buttonsHeightRem = `${(buttonsHeight / 10).toFixed(1)}rem`
  const buttonsStyle = isMobile
    ? { paddingBottom: buttonsHeightRem }
    : undefined

  useEffect(() => {
    dispatch(closeModal())
  }, [dispatch])

  useEffect(() => {
    if (!auth) navigate('/guest')
  }, [auth, navigate])

  useEffect(() => {
    dispatch(fetchCustomer())
    dispatch(fetchCustomerCreditCards(true))
    dispatch(fetchCustomerOrders(20))
    dispatch(fetchCustomerFavorites())
    dispatch(fetchCustomerGroupOrders())
  }, [token, dispatch, navigate])

  // Use the custom hook instead of dispatching directly
  useAnnouncementPage('ACCOUNT')

  useEffect(() => {
    if (hasDeals) dispatch(fetchDeals())
  }, [hasDeals, dispatch])

  useEffect(() => {
    if (hasRewards) dispatch(fetchCustomerRewards())
  }, [hasRewards, dispatch])

  if (!auth) return null

  return (
    <>
      <Helmet>
        <title>
          {greeting} | {siteTitle}
        </title>
      </Helmet>
      <Background
        imageUrl={background}
        videoUrl={videoUrl}
        announcements={displayAnnouncements ? announcements : undefined}
        style={{ top: theme.layout.navHeight }}
      />
      <Content maxWidth="76.8rem" hasFooter={isMobile ? false : true}>
        <Header
          style={{ boxShadow: 'none' }}
          maxWidth="100%"
          borderColor={isMobile ? 'transparent' : undefined}
          title={
            showScan ? <AccountScan /> : showLogo ? <HeaderLogo /> : undefined
          }
          left={isMobile ? <User /> : <HeaderLogo />}
          right={
            <>
              {!isMobile && <User />}
              <Cart />
              <NavMenu />
            </>
          }
        />
        <Main>
          <AccountWrapper>
            <AccountView style={buttonsStyle}>
              <Welcome
                title={greeting}
                subtitle={!isMobile ? subtitle : undefined}
              />
              {isMobile && <AccountMobile>{displayedSections}</AccountMobile>}
              <AccountButtons ref={buttons} />
              {!isMobile && <AccountMobile>{displayedSections}</AccountMobile>}
            </AccountView>
          </AccountWrapper>
        </Main>
      </Content>
    </>
  )
}

export default Account
