import { useRef, useEffect, useCallback, useState } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import styled from '@emotion/styled'
import { Address as AddressType } from '@open-tender/types'
import {
  selectModal,
  closeModal,
  openModal,
  resetAlert,
  selectAlert,
  toggleSidebar,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import ModalOverlay from './ModalOverlay'
import ModalLoading from './ModalLoading'
import {
  Address,
  AdjustRequestedAt,
  AdjustRequestedAtProps,
  Allergens,
  CartCounts,
  CartErrors,
  Closed,
  ClosedModalProps,
  CreditCard,
  CreditCardLinked,
  CreditCardModalProps,
  ClaimRewardCode,
  DeleteAccount,
  ForgotPassword,
  GiftCard,
  GiftCardAssign,
  GiftCardAssignOther,
  GiftCardAssignOtherModalProps,
  GroupOrder,
  GroupOrderLeave,
  GroupOrderType,
  GroupOrderTypeModalProps,
  ItemInfo,
  ItemInfoModalProps,
  ItemModal,
  ItemNotes,
  ItemNotesModalProps,
  Login,
  LoyaltyTier,
  LoyaltyTierModalProps,
  MapsAutocomplete,
  OrderRating,
  OrderRatingProps,
  OrderType,
  Points,
  PointsShopReward,
  PointsShopRewardModalProps,
  PrepType,
  Profile,
  QRCode,
  QRCodeModalProps,
  RequestedAt,
  RequestedAtModalProps,
  Reward,
  RewardModalProps,
  SignUp,
  Tags,
  Working,
} from '../modals'

const makeModal = (
  type: string,
  windowRef: React.RefObject<HTMLDivElement>,
  args: Record<string, unknown> | null
) => {
  switch (type) {
    case 'address':
      return (
        <Address windowRef={windowRef} address={args?.address as AddressType} />
      )
    case 'adjustRequestedAt': {
      const { firstTimes, revenueCenter } = args as AdjustRequestedAtProps
      return (
        <AdjustRequestedAt
          firstTimes={firstTimes}
          revenueCenter={revenueCenter}
        />
      )
    }
    case 'allergens':
      return <Allergens {...args} />
    case 'tags':
      return <Tags {...args} />
    case 'cartCounts':
      return <CartCounts {...args} />
    case 'cartErrors':
      return <CartErrors {...args} />
    case 'closed': {
      const closedArgs = args as ClosedModalProps
      const { status, isCancel } = closedArgs
      return <Closed status={status} isCancel={isCancel} />
    }
    case 'creditCard': {
      const creditCardArgs = args as CreditCardModalProps
      const { callback, revenue_center_id } = creditCardArgs || {}
      return (
        <CreditCard
          windowRef={windowRef}
          callback={callback}
          revenue_center_id={revenue_center_id}
        />
      )
    }
    case 'creditCardLinked':
      return <CreditCardLinked {...args} />
    case 'claimRewardCode':
      return <ClaimRewardCode windowRef={windowRef} />
    case 'forgotPassword':
      return <ForgotPassword {...args} />
    case 'giftCard':
      return <GiftCard windowRef={windowRef} {...args} />
    case 'giftCardAssign':
      return <GiftCardAssign windowRef={windowRef} {...args} />
    case 'giftCardAssignOther': {
      const { giftCardId } = args as GiftCardAssignOtherModalProps
      return (
        <GiftCardAssignOther windowRef={windowRef} giftCardId={giftCardId} />
      )
    }
    case 'groupOrder':
      return <GroupOrder {...args} />
    case 'groupOrderType':
      const { revenueCenter, serviceTypes } = (args ||
        {}) as GroupOrderTypeModalProps
      return (
        <GroupOrderType
          revenueCenter={revenueCenter}
          serviceTypes={serviceTypes}
        />
      )
    case 'groupOrderLeave':
      return <GroupOrderLeave {...args} />
    case 'item':
      return <ItemModal {...args} />
    case 'itemInfo': {
      const { name, description, nutritionalInfo, ingredients } =
        args as ItemInfoModalProps
      return (
        <ItemInfo
          name={name}
          description={description}
          nutritionalInfo={nutritionalInfo}
          ingredients={ingredients}
        />
      )
    }
    case 'itemNotes': {
      const { title, hasMadeFor, hasNotes, madeFor, notes, setMadeForNotes } =
        args as ItemNotesModalProps
      return (
        <ItemNotes
          title={title}
          hasMadeFor={hasMadeFor}
          hasNotes={hasNotes}
          madeFor={madeFor}
          notes={notes}
          setMadeForNotes={setMadeForNotes}
          {...args}
        />
      )
    }
    case 'login':
      return <Login {...args} />
    case 'loyaltyTier': {
      const { tier } = args as LoyaltyTierModalProps
      return <LoyaltyTier tier={tier} />
    }
    case 'mapsAutocomplete':
      return <MapsAutocomplete {...args} />
    case 'orderType':
      return <OrderType {...args} />
    case 'qrCode': {
      const { src, alt, title, description, alert, footnote, isLoyalty } =
        args as QRCodeModalProps
      return (
        <QRCode
          src={src}
          alt={alt}
          title={title}
          description={description}
          alert={alert}
          footnote={footnote}
          isLoyalty={isLoyalty}
        />
      )
    }
    case 'points':
      return <Points {...args} />
    case 'pointsShopReward': {
      const { reward, pointsBalance } = args as PointsShopRewardModalProps
      return <PointsShopReward reward={reward} pointsBalance={pointsBalance} />
    }
    case 'prepType':
      return <PrepType {...args} />
    case 'profile':
      return <Profile windowRef={windowRef} {...args} />
    case 'rating': {
      const { orderId, orderRating } = args as OrderRatingProps
      return <OrderRating orderId={orderId} orderRating={orderRating} />
    }
    case 'requestedAt': {
      const {
        revenueCenter,
        serviceType,
        orderType,
        requestedAt,
        openSidebar,
        isReorder,
        isGroupOrder,
      } = args as RequestedAtModalProps
      return (
        <RequestedAt
          revenueCenter={revenueCenter}
          serviceType={serviceType}
          orderType={orderType}
          requestedAt={requestedAt}
          openSidebar={openSidebar}
          isReorder={isReorder}
          isGroupOrder={isGroupOrder}
        />
      )
    }
    case 'reward': {
      const { reward } = args as RewardModalProps
      return <Reward reward={reward} />
    }
    case 'deleteAccount':
      return <DeleteAccount {...args} />
    case 'signUp':
      return <SignUp windowRef={windowRef} {...args} />
    case 'working':
      return <Working {...args} />
    default:
      return null
  }
}

const containerStyleMap: Record<string, React.CSSProperties> = {
  address: { alignItems: 'flex-start' },
  creditCard: { alignItems: 'flex-start' },
  cartErrors: { alignItems: 'flex-start' },
  cartCounts: { alignItems: 'flex-start' },
  signUp: { alignItems: 'flex-start' },
  profile: { alignItems: 'flex-start' },
  allergens: { alignItems: 'flex-start' },
  groupOrder: { alignItems: 'flex-start' },
}

const ModalContainer = styled.div`
  position: fixed;
  z-index: 110;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Modal = () => {
  const modalRef = useRef<HTMLDivElement | null>(null)
  const [active, setActive] = useState<HTMLElement | null>(null)
  const [elements, setElements] = useState<Element[]>([])
  const [, setInputs] = useState<Element[]>([])
  const dispatch = useAppDispatch()
  const alert = useAppSelector(selectAlert)
  const { loading, type, args } = useAppSelector(selectModal)
  const focusFirst = args && args.focusFirst ? true : false
  const skipClose = args && args.skipClose ? true : false
  const preventClose = args && args.preventClose ? true : false
  const style = args && args.style ? args.style : {}
  const showModal = type ? true : false
  const modal = type ? makeModal(type, modalRef, args) : null
  let containerStyle = type ? containerStyleMap[type] : null
  containerStyle = containerStyle ? { ...containerStyle, ...style } : style
  const isWorking = type === 'working'

  useEffect(() => {
    if (alert) {
      if (alert.type === 'closeAndSidebar') {
        dispatch(closeModal())
        dispatch(toggleSidebar())
      } else if (alert.type === 'close') {
        dispatch(closeModal())
      } else {
        dispatch(openModal(alert))
      }
      dispatch(resetAlert())
    }
  }, [alert, dispatch])

  const handleClose = (evt: React.MouseEvent<HTMLElement>) => {
    if (!preventClose && (evt.target as Element).id === 'modal-container') {
      dispatch(closeModal())
    }
  }

  const handleExit = () => {
    if (active) active.focus()
  }

  const handleFocus = () => {
    setActive(document.activeElement as HTMLElement)
    const allElements = modalRef.current?.querySelectorAll(
      'a[href], button, input, select, textarea'
    )
    const elements = Array.from(allElements ?? [])
    setElements(elements)
    const allInputs = modalRef.current?.querySelectorAll(
      'input, select, textarea'
    )
    const inputs = Array.from(allInputs ?? [])
    setInputs(inputs)
    const firstElement =
      !focusFirst && allInputs?.length
        ? allInputs[0]
        : allElements
        ? allElements[skipClose ? 1 : 0]
        : null
    if (firstElement) (firstElement as any).focus()
  }

  const handleTabKey = useCallback(
    (evt: KeyboardEvent) => {
      if (evt.keyCode === 9 && modalRef.current && elements.length) {
        const activeElements = Array.from(elements).filter(
          (i: any) => !i.disabled
        )
        const firstElement = activeElements[0]
        const lastElement = activeElements[activeElements.length - 1]

        if (!evt.shiftKey && document.activeElement === lastElement) {
          ;(firstElement as any).focus()
          evt.preventDefault()
        }

        if (evt.shiftKey && document.activeElement === firstElement) {
          ;(lastElement as any).focus()
          evt.preventDefault()
        }
      } else if (evt.keyCode === 9 && isWorking) {
        evt.preventDefault()
      }
    },
    [elements, isWorking]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleTabKey, false)
    return () => document.removeEventListener('keydown', handleTabKey, false)
  }, [handleTabKey])

  return (
    <>
      <ModalOverlay show={showModal || loading} />
      <ModalLoading show={loading} />
      <TransitionGroup component={null}>
        {showModal ? (
          <CSSTransition
            key="modal"
            classNames="md"
            timeout={{ enter: 250, exit: 250 }}
            onEntered={!isWorking ? handleFocus : () => undefined}
            onExited={!isWorking ? handleExit : () => undefined}
          >
            <ModalContainer
              ref={modalRef}
              id="modal-container"
              onClick={handleClose}
              style={containerStyle}
            >
              {modal}
            </ModalContainer>
          </CSSTransition>
        ) : null}
      </TransitionGroup>
    </>
  )
}

export default Modal
