import {
  ClaimedPointsCard,
  ClaimPointsForm,
  Content,
  HeaderDefault,
  Main,
  PageContainer,
  PageContent,
} from 'components'
import { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'

const ClaimPoints = () => {
  const [claimed, setClaimed] = useState(false)
  const [claimedPoints, setClaimedPoints] = useState(0)

  const handleClaimed = (claimedPoints: number) => {
    setClaimed(true)
    setClaimedPoints(claimedPoints)
  }
  const reset = () => {
    setClaimed(false)
    setClaimedPoints(0)
  }

  useEffect(() => {
    reset()
  }, [])

  return (
    <>
      <Helmet>
        <title>Claim Points</title>
      </Helmet>
      <Content>
        <HeaderDefault />
        <Main>
          <PageContainer>
            <PageContent style={{ maxWidth: '72rem' }}>
              {claimed ? (
                <ClaimedPointsCard
                  onClose={reset}
                  claimedPoints={claimedPoints}
                />
              ) : (
                <ClaimPointsForm callback={handleClaimed} />
              )}
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default ClaimPoints
