import { CSSProperties } from 'react'
import styled from '@emotion/styled'
import { ThemeFontSize } from '@open-tender/types'
import { roundPoints } from '@open-tender/utils'
import {
  openModal,
  selectCustomerPoints,
  selectOrder,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Button } from 'components'
import { Star } from 'components/icons'

const PointsTitle = styled.span``

const PointsIcon = styled.span`
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  margin: 0 0 0 0.4rem;
`

const defaultStyle = { paddingTop: '0', paddingBottom: '0', height: '3.2rem' }

const Points = ({
  color = 'cart',
  size = 'small',
  style = defaultStyle,
}: {
  color?: string
  size?: ThemeFontSize
  style?: CSSProperties
}) => {
  const dispatch = useAppDispatch()
  const order = useAppSelector(selectOrder)
  const loyaltyPoints = useAppSelector(selectCustomerPoints(order.orderType))
  const { name, points } = loyaltyPoints || {}

  const onClick = () => {
    dispatch(openModal({ type: 'points' }))
  }

  return points ? (
    <Button
      onClick={onClick}
      label={`${name} balance`}
      color={color}
      size={size}
      style={style}
    >
      <PointsTitle>{roundPoints(points)}</PointsTitle>
      <PointsIcon>
        <Star />
      </PointsIcon>
    </Button>
  ) : null
}

export default Points
